import { fetcher } from './apim-api';
import {
  states,
  getters,
  mutations,
  actions,
  getterFns,
  mutationFns,
  actionFns,
} from './stateFuncs';

// States
const PROJECTS = 'projects';
const ORGS = 'orgs';
// Mutations
const SAVE_ORGS = 'SITE_ADMIN_SAVE_ORGS';
const SAVE_ORG = 'SITE_ADMIN_SAVE_ORG';
const SAVE_PROJECTS = 'SITE_ADMIN_SAVE_PROJECTS';
const SAVE_PROJECT = 'SITE_ADMIN_SAVE_PROJECT';
const ORG_NEEDED = 'ORG_NEEDED';

const siteAdmin = {
  namespaced: true,
  state: {
    errors: {},
    [ORGS]: states.init(),
    [PROJECTS]: {},
  },
  getters: {
    orgsNeeded: getters.needed(ORGS),
    orgsLoaded: getters.loaded(ORGS),
    orgs: getters.listOf(ORGS, 'name'),
    org: getters.getById(ORGS, ({ org_id: orgId }) => orgId),

    projectsNeeded: getterFns.needed(PROJECTS),
    projectsLoaded: getterFns.loaded(PROJECTS),
    projects: getterFns.listOf(PROJECTS, 'name'),
    project: getterFns.getById(PROJECTS),
  },
  mutations: {
    [SAVE_ORGS]: mutations.saveList(ORGS),
    [SAVE_ORG]: mutations.saveItem(ORGS),

    [SAVE_PROJECTS]: mutationFns.saveList(PROJECTS),
    [SAVE_PROJECT]: mutationFns.saveItem(PROJECTS),

    [ORG_NEEDED]: (state) => {
      state[ORGS][states.STATUS] = states.STATE.NOT_SET;
    },
  },
  actions: {
    fetchOrgs: actions.list(SAVE_ORGS, fetcher.adminGetOrgs.bind(fetcher)),
    fetchOrg: actionFns.get(SAVE_ORG, fetcher.adminGetOrg.bind(fetcher)),
    fetchProjects: actionFns.list(
      SAVE_PROJECTS,
      fetcher.getProjectsInOrg.bind(fetcher),
      ({ orgId }) => orgId,
    ),
    fetchProject: actionFns.get(SAVE_PROJECT, fetcher.adminGetProject.bind(fetcher)),
    needOrgs({ commit }) {
      commit(ORG_NEEDED);
    },
  },
};

export default siteAdmin;
