export const AUTH = 'READ_AUTH';
export const CALL = 'READ_CALL';
export const DOWNTIME = 'READ_DOWNTIME';
export const ENVIRONMENT = 'READ_ENVIRONMENT';
export const FILE = 'READ_FILE';
export const REPORT = 'READ_REPORT';
export const SCHEDULE = 'READ_SCHEDULE';
export const SUBSCRIPTION = 'READ_SUBSCRIPTION';
export const TOKEN = 'READ_TOKEN';
export const USER = 'READ_USER';
export const WORKFLOW = 'READ_WORKFLOW';
