import _toPath from 'lodash/toPath';
import _sortBy from 'lodash/sortBy';
import _get from 'lodash/get';
import { STATE, ALL, STATUS } from './states';

function needed(name) {
  const fn = (state) => (pathIn) => {
    const path = _toPath(pathIn);
    const status = _get(state, [name, ...path, STATUS]);
    return !status || status === STATE.NOT_SET;
  };
  return fn;
}

function loaded(name) {
  const fn = (state) => (pathIn) => {
    const path = _toPath(pathIn);
    return _get(state, [name, ...path, STATUS]) === STATE.SUCCESS;
  };
  return fn;
}

function listOf(name, sortByFn) {
  const fn = (state) => (pathIn) => {
    const path = _toPath(pathIn);
    const status = _get(state, [name, ...path, STATUS]);
    if (status === STATE.SUCCESS) {
      const all = _get(state, [name, ...path, ALL]);
      console.assert(all, `getter-fns.listOf - ${name} ${path} ALL is not set`);
      const values = Object.values(all);
      return _sortBy(values, [(item) => _get(item, sortByFn).toLowerCase()]);
    }
    return [];
  };
  return fn;
}

function getById(name) {
  const fn = (state) => (pathIn, id) => {
    const path = _toPath(pathIn);
    return _get(state, [name, ...path, ALL, id]);
  };
  return fn;
}

export { needed, loaded, listOf, getById };
