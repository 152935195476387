import _toPath from 'lodash/toPath';
import Vue from 'vue';
import { init, STATE, ALL, STATUS } from './states';

function prepareState(state, name, pathArr) {
  const subPath = [name, ...pathArr];
  const lastPathItem = subPath.pop();

  const theState = subPath.reduce((theStateIn, key) => {
    if (!theStateIn[key]) {
      Vue.set(theStateIn, key, {});
    }
    return theStateIn[key];
  }, state);
  if (!theState[lastPathItem]) {
    Vue.set(theState, lastPathItem, init());
  }
  return theState[lastPathItem];
}

function saveList(name, prefix = 'err') {
  const fn = (state, { status, path, results, error }) => {
    console.assert(status, `mutation-fns.saveItem - ${name} status is not set`);
    console.assert(path, `mutation-fns.saveItem -  ${name} path is not set`);
    const pathArr = _toPath(path);
    const theState = prepareState(state, name, pathArr);
    console.assert(theState, `mutation-fns.saveItem -  ${name} theState is not set`);
    if (status === STATE.SUCCESS) {
      const all = theState[ALL];
      console.assert(all, `mutation-fns.saveList -  ${name} all is not set`);
      console.assert(results, `mutation-fns.saveList - ${name} all is not set`);
      const objs = results.reduce((currObj, obj) => {
        const out = currObj;
        out[obj.id] = obj;
        return out;
      }, all);
      Vue.set(theState, ALL, objs);
    }
    if (status === STATE.FAILED) {
      const errName = [prefix, name].join('-');
      Vue.set(state.errors, errName, error);
    }
    Vue.set(theState, STATUS, status);
  };
  return fn;
}

function saveItem(name, prefix = 'err') {
  const fn = (state, { status, path, id, data, error }) => {
    console.assert(status, `mutations-fns.saveItem - ${name} status is not set`);
    const pathArr = _toPath(path);
    if (status === STATE.SUCCESS) {
      const theState = prepareState(state, name, pathArr);
      console.assert(theState, `mutations-fns.saveItem - ${name} theState is not set`);
      const all = theState[ALL];
      console.assert(all, `mutations-fns.saveItem - ${name} ALL is not set`);
      if (data) {
        Vue.set(all, id, data);
      } else {
        Vue.delete(all, id);
      }
      Vue.set(theState, STATUS, status);
    }
    if (status === STATE.FAILED) {
      console.assert(id, `mutations-fns.saveItem - ${name} id is not set`);
      const errName = [prefix, name, ...pathArr, id].join('-');
      Vue.set(state.errors, errName, error);
    }
  };
  return fn;
}

export { saveList, saveItem };
