import Vue from 'vue';
import { STATE, ALL, getPath, setVuePath } from './state';

function mutationSaveList(stateKey) {
  return (state, { status, data, error }) => {
    // console.debug('mutationSaveList', stateKey, status, data);
    // eslint-disable-next-line no-param-reassign
    Vue.set(state.loading[stateKey], ALL, status);
    if (status === STATE.SUCCESS) {
      const results = data || [];
      const newLoading = results.reduce((soFar, { id }) => {
        const out = soFar;
        out[id] = STATE.SUCCESS;
        return out;
      }, {});
      const newState = results.reduce((soFar, obj) => {
        const out = soFar;
        out[obj.id] = obj;
        return out;
      }, {});
      Vue.set(state.loading, stateKey, { ...state.loading[stateKey], ...newLoading });
      Vue.set(state, stateKey, { ...state[stateKey], ...newState });
    }
    if (status === STATE.FAILED) {
      console.error(error);
    }
  };
}

// We're saving state at state.[...statePath][stateKey]
function mutationSaveById(statePathFn, stateKeyFn) {
  return (state, { status, data, error, params }) => {
    const statePath = getPath(statePathFn, stateKeyFn, params, data);
    // console.debug('mutationSaveById', statePath, status, data, error);

    setVuePath(state.loading, statePath, status);

    if (status === STATE.FAILED) {
      setVuePath(state, [...statePath, 'error'], error);
    } else if (status === STATE.SUCCESS) {
      setVuePath(state, statePath, data);
    }
  };
}

export { mutationSaveList, mutationSaveById };
