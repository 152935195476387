import Vue from 'vue';
import LoadingAnim from './components/Loading.vue';
import ErrorComponent from './components/Error.vue';

const delay = 2;
const timeout = 30000;

Vue.component('ErrorComponent', ErrorComponent);
Vue.component('LoadingAnim', LoadingAnim);

function delayLoad(componentFn, showLoading = true) {
  return () => ({
    component: componentFn(),
    loading: showLoading ? LoadingAnim : null,
    error: ErrorComponent,
    delay,
    timeout,
  });
}

export default delayLoad;
