import _groupBy from 'lodash/groupBy';
import Vue from 'vue';
import { fetcher, handleException, STATE } from './apim-api';
import {
  states,
  getters,
  mutations,
  actions,
  getterFns,
  mutationFns,
  actionFns,
} from './stateFuncs';

// States
const ACCOUNTS = 'accounts';
const INVITES = 'invites';
const PROJ_INVITES = 'projInvites';
const PROJECTS = 'projects';
const ROLES = 'roles';
const API_KEYS = 'apiKeys';
// These are keyed by projectId, then have list data inside
const ROLES_FOR_PROJ = 'rolesForProject';
const ACCESS_FOR_PROJ = 'accessForProject';

// Mutations
const SAVE_ACCOUNTS = 'ADMIN_SAVE_ACCOUNTS';
const SAVE_ACCOUNT = 'ADMIN_SAVE_ACCOUNT';
const SAVE_INVITES = 'ADMIN_SAVE_INVITES';
const SAVE_INVITE = 'ADMIN_SAVE_INVITE';
const SAVE_PROJ_INVITES = 'ADMIN_SAVE_PROJ_INVITES';
const SAVE_PROJ_INVITE = 'ADMIN_SAVE_PROJ_INVITE';
const SAVE_PROJECTS = 'ADMIN_SAVE_PROJECTS';
const SAVE_PROJECT = 'ADMIN_SAVE_PROJECT';
const SAVE_ROLES_FOR_PROJECT = 'ADMIN_SAVE_ROLES_FOR_PROJECT';
const SAVE_ROLE_FOR_PROJECT = 'ADMIN_SAVE_ROLE_FOR_PROJECT';
const SAVE_ACCESSES_FOR_PROJECT = 'ADMIN_SAVE_ACCESSES_FOR_PROJECT';
const SAVE_ACCESS_FOR_PROJECT = 'ADMIN_SAVE_ACCESS_FOR_PROJECT';
const SAVE_ROLES = 'ADMIN_SAVE_ROLES';
const SAVE_ROLE = 'ADMIN_SAVE_ROLE';
const SAVE_API_KEYS = 'ADMIN_SAVE_API_KEYS';
const SAVE_API_KEY = 'ADMIN_SAVE_API_KEY';
const UPDATE_PROJECT = 'ADMIN_UPDATE_PROJECT';
const CLEAR_PROJECT_CACHE = 'CLEAR_PROJECT_CACHE';
const CLEAR_CACHE = 'CLEAR_CACHE';

function initialState() {
  return {
    errors: {},
    [ACCOUNTS]: states.init(),
    [INVITES]: states.init(),
    [PROJ_INVITES]: states.init(),
    [PROJECTS]: states.init(),
    [ROLES]: states.init(),
    [API_KEYS]: states.init(),
    // These are keyed by projectId, then have list data inside
    [ROLES_FOR_PROJ]: {},
    [ACCESS_FOR_PROJ]: {},
  };
}

const admin = {
  namespaced: true,
  state: initialState(),
  getters: {
    accountsNeeded: getters.needed(ACCOUNTS),
    accountsLoaded: getters.loaded(ACCOUNTS),
    accounts: getters.listOf(ACCOUNTS, 'email'),
    account: getters.getById(ACCOUNTS),

    invitesNeeded: getters.needed(INVITES),
    invitesLoaded: getters.loaded(INVITES),
    invites: getters.listOf(INVITES, 'email'),
    invite: getters.getById(INVITES),

    projInvitesNeeded: getters.needed(PROJ_INVITES),
    projInvitesLoaded: getters.loaded(PROJ_INVITES),
    projInvites: getters.listOf(PROJ_INVITES, 'email'),
    projInvite: getters.getById(PROJ_INVITES),

    projectsNeeded: getters.needed(PROJECTS),
    projectsLoaded: getters.loaded(PROJECTS),
    projects: getters.listOf(PROJECTS, 'name'),
    project: getters.getById(PROJECTS),

    rolesNeeded: getters.needed(ROLES),
    rolesLoaded: getters.loaded(ROLES),
    roles: getters.listOf(ROLES, 'id'),
    role: getters.getById(ROLES),

    apiKeysNeeded: getters.needed(API_KEYS),
    apiKeysLoaded: getters.loaded(API_KEYS),
    apiKeys: getters.listOf(API_KEYS, 'id'),
    apiKey: getters.getById(API_KEYS),

    // Uses path
    rolesForProjectNeeded: getterFns.needed(ROLES_FOR_PROJ),
    rolesForProjectLoaded: getterFns.loaded(ROLES_FOR_PROJ),
    rolesForProject: getterFns.listOf(ROLES_FOR_PROJ, 'id'),
    roleForProject: getterFns.getById(ROLES_FOR_PROJ),

    accessForProjectNeeded: getterFns.needed(ACCESS_FOR_PROJ),
    accessForProjectLoaded: getterFns.loaded(ACCESS_FOR_PROJ),
    accessForProject: getterFns.listOf(ACCESS_FOR_PROJ, 'id'),
    accessItemForProject: getterFns.getById(ACCESS_FOR_PROJ),
  },
  mutations: {
    [SAVE_ACCOUNTS]: mutations.saveList(ACCOUNTS),
    [SAVE_ACCOUNT]: mutations.saveItem(ACCOUNTS),

    [SAVE_INVITES]: mutations.saveList(INVITES),
    [SAVE_INVITE]: mutations.saveItem(INVITES),

    [SAVE_PROJ_INVITES]: mutations.saveList(PROJ_INVITES),
    [SAVE_PROJ_INVITE]: mutations.saveItem(PROJ_INVITES),

    [SAVE_PROJECTS]: mutations.saveList(PROJECTS),
    [SAVE_PROJECT]: mutations.saveItem(PROJECTS),

    [SAVE_ROLES]: mutations.saveList(ROLES),
    [SAVE_ROLE]: mutations.saveItem(ROLES),

    [SAVE_ROLES_FOR_PROJECT]: mutationFns.saveList(ROLES_FOR_PROJ),
    [SAVE_ROLE_FOR_PROJECT]: mutationFns.saveItem(ROLES_FOR_PROJ),

    [SAVE_ACCESSES_FOR_PROJECT]: mutationFns.saveList(ACCESS_FOR_PROJ),
    [SAVE_ACCESS_FOR_PROJECT]: mutationFns.saveItem(ACCESS_FOR_PROJ),

    [SAVE_API_KEYS]: mutations.saveList(API_KEYS),
    [SAVE_API_KEY]: mutations.saveItem(API_KEYS),

    [UPDATE_PROJECT]: (state, { id, data }) => {
      if (data && state[PROJECTS][states.ALL][id]) {
        Object.entries(data).forEach(([key, val]) => {
          state[PROJECTS][states.ALL][id][key] = val;
        });
      } else if (!data) {
        Vue.delete(state[PROJECTS][states.ALL], id);
        return;
      }
      const { name } = data;
      if (name && state[ACCESS_FOR_PROJ][id]) {
        Object.values(state[ACCESS_FOR_PROJ][id][states.ALL]).forEach((access) => {
          // eslint-disable-next-line no-param-reassign
          access.project_name = name;
        });
      }
    },
    [CLEAR_PROJECT_CACHE]: (state) => {
      Vue.set(state, PROJ_INVITES, states.init());
    },
    [CLEAR_CACHE]: (state) => {
      const cleared = initialState();
      Object.entries(cleared).forEach(([key, val]) => {
        Vue.set(state, key, val);
      });
    },
  },
  actions: {
    fetchAccounts: actions.list(SAVE_ACCOUNTS, fetcher.getAccountsInOrg.bind(fetcher)),
    // createAccount: n/a - createAccountInOrg
    // updateAccount: tba - updateAccountInOrg
    addRoleToAccount: actions.update(SAVE_ACCOUNT, fetcher.addRoleToAccountInOrgById.bind(fetcher)),
    deleteRoleFromAccount: actions.update(
      SAVE_ACCOUNT,
      fetcher.deleteRoleFromAccountInOrgById.bind(fetcher),
    ),
    getAccount: actions.get(SAVE_ACCOUNT, fetcher.getAccountInOrgById.bind(fetcher)),
    deleteAccount: actions.remove(SAVE_ACCOUNT, fetcher.deleteAccountInOrgById.bind(fetcher)),

    fetchInvites: actions.list(SAVE_INVITES, fetcher.getInvitesInOrg.bind(fetcher)),
    createInvite: actions.create(SAVE_INVITE, fetcher.createInviteInOrg.bind(fetcher)),
    // updateInvite: n/a - updateInviteInOrg
    // getInvite: fetchGet(SAVE_INVITE, fetcher.getInviteInOrgById.bind(fetcher)),
    deleteInvite: actions.remove(SAVE_INVITE, fetcher.deleteInviteInOrgById.bind(fetcher)),

    fetchProjInvites: actions.list(SAVE_PROJ_INVITES, fetcher.getInvitesForProj.bind(fetcher)),
    createProjInvite: actions.create(SAVE_PROJ_INVITE, fetcher.createInviteForProj.bind(fetcher)),
    // updateInvite: n/a - updateInviteInOrg
    // getInvite: fetchGet(SAVE_PROJ_INVITE, fetcher.getInviteInOrgById.bind(fetcher)),
    deleteProjInvite: actions.remove(
      SAVE_PROJ_INVITE,
      fetcher.deleteInviteForProjById.bind(fetcher),
    ),

    // fetchProjects: actions.list(SAVE_PROJECTS, fetcher.getProjectsInOrg.bind(fetcher)),
    fetchProjects: async ({ commit }, params) => {
      commit(SAVE_PROJECTS, { status: STATE.PENDING });
      return fetcher
        .getProjectsInOrg({
          ...params,
          pageCb: (resp) => {
            const { results } = resp;
            commit(SAVE_PROJECTS, { status: STATE.SUCCESS, results });

            // Legacy projects have an email
            const legacy = results.filter((res) => res.email);

            const invites = legacy.map(({ id, email, created }) => ({
              id,
              access_level: 'VIEWER',
              org_id: 'org_id',
              project_id: null,
              name: 'orgName',
              email,
              invited_by: 'LEGACY USER',
              invited_email: 'support@apimetrics.com',
              created,
              legacy: true,
            }));
            commit(SAVE_INVITES, { status: STATE.SUCCESS, results: invites });

            results.forEach(({ id }) => {
              const emptyData = { status: STATE.NOT_SET, path: id, results: [] };
              commit(SAVE_ACCESSES_FOR_PROJECT, emptyData);
              commit(SAVE_ROLES_FOR_PROJECT, emptyData);
            });

            legacy.forEach(({ id, email, created, name, organization }) => {
              const access = {
                id,
                access_level: 'OWNER',
                project: {
                  id,
                  name,
                  organization,
                },
                organization: {
                  id: organization,
                },
                account_id: email,
                account_email: email,
                created,
                legacy: true,
              };
              commit(SAVE_ACCESSES_FOR_PROJECT, {
                status: STATE.SUCCESS,
                path: id,
                results: [access],
              });
            });
          },
        })
        .catch(async (except) => {
          const error = await handleException(except);
          commit(SAVE_PROJECTS, { state: STATE.FAILED, error });
          return null;
        });
    },
    createProject: actions.create(SAVE_PROJECT, fetcher.createProjectInOrg.bind(fetcher)),
    // updateProject: actions.update(SAVE_PROJECT, fetcher.updateProjectInOrgById.bind(fetcher)),
    getProjectAccess: actions.get(SAVE_PROJECT, fetcher.getProjectInOrgById.bind(fetcher)),
    deleteProject: actions.remove(SAVE_PROJECT, fetcher.deleteProjectInOrgById.bind(fetcher)),

    getRolesForProject: actionFns.list(
      SAVE_ROLES_FOR_PROJECT,
      fetcher.getRolesForProjectById.bind(fetcher),
    ),
    createRoleForProject: actionFns.create(
      SAVE_ROLE_FOR_PROJECT,
      fetcher.createRoleForProjectById.bind(fetcher),
    ),
    deleteRoleForProject: actionFns.remove(
      SAVE_ROLE_FOR_PROJECT,
      fetcher.deleteRoleForProjectById.bind(fetcher),
      ({ projectId }) => projectId,
    ),
    getAccessForProject: actionFns.list(
      SAVE_ACCESSES_FOR_PROJECT,
      fetcher.getAccessForProjectById.bind(fetcher),
    ),
    createAccessForProject: actionFns.create(
      SAVE_ACCESS_FOR_PROJECT,
      fetcher.createAccessForProjectById.bind(fetcher),
    ),
    deleteAccessForProject: actionFns.remove(
      SAVE_ACCESS_FOR_PROJECT,
      fetcher.deleteAccessForProjectById.bind(fetcher),
      ({ projectId }) => projectId,
    ),
    fetchRoles: actions.list(SAVE_ROLES, fetcher.getRolesInOrg.bind(fetcher)),
    createRole: actions.create(SAVE_ROLE, fetcher.createRoleInOrg.bind(fetcher)),
    updateRole: actions.update(SAVE_ROLE, fetcher.updateRoleInOrgById.bind(fetcher)),
    // getRole: fetchGet(SAVE_ROLE, fetcher.getRoleInOrgById.bind(fetcher)),
    deleteRole: actions.remove(SAVE_ROLE, fetcher.deleteRoleInOrgById.bind(fetcher)),

    fetchApiKeys: actions.list(SAVE_API_KEYS, fetcher.getApiKeys.bind(fetcher)),
    createApiKey: actions.create(SAVE_API_KEY, fetcher.createApiKey.bind(fetcher)),
    // updateApiKey: actions.update(SAVE_API_KEY, fetcher.updateApiKeyById.bind(fetcher)),
    // getApiKey: fetchGet(SAVE_API_KEY, fetcher.getApiKeyById.bind(fetcher)),
    deleteApiKey: actions.remove(SAVE_API_KEY, fetcher.deleteApiKeyById.bind(fetcher)),

    getAccessForOrg: ({ commit }, params) =>
      fetcher.getAccessForOrg({
        ...params,
        pageCb: (resp) => {
          const { results: resIn } = resp;
          const groups = _groupBy(resIn, 'project.id');
          // console.log('groups', groups);

          Object.keys(groups).forEach((projId) => {
            // console.log('Access proj', projId);
            const results = groups[projId];
            commit(SAVE_ACCESSES_FOR_PROJECT, {
              status: STATE.SUCCESS,
              path: projId,
              results,
            });
          });
        },
      }),
    getRolesForOrg: ({ commit }, params) =>
      fetcher.getRolesForOrg({
        ...params,
        pageCb: (resp) => {
          const { results: resIn } = resp;
          const groups = _groupBy(resIn, 'project.id');
          // console.log('groups', groups);

          Object.keys(groups).forEach((projId) => {
            // console.log('Access proj', projId);
            const results = groups[projId];
            commit(SAVE_ROLES_FOR_PROJECT, {
              status: STATE.SUCCESS,
              path: projId,
              results,
            });
          });
        },
      }),

    updateProject: ({ commit }, { id, data }) => {
      commit(UPDATE_PROJECT, { id, data });
      commit(CLEAR_PROJECT_CACHE);
    },

    clearAll: ({ commit }) => {
      commit(CLEAR_CACHE);
    },
  },
};

export default admin;
