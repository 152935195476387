<template>
  <div class="container-fluid">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'GridContainer',
};
</script>
