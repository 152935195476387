<template>
  <div :class="classes">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'GridColumn',
  props: {
    lg: {
      type: Number,
      required: false,
      default: 0,
    },
    md: {
      type: Number,
      required: false,
      default: 0,
    },
    sm: {
      type: Number,
      required: false,
      default: 0,
    },
    xs: {
      type: Number,
      required: false,
      default: 0,
    },
    lgOffset: {
      type: Number,
      required: false,
      default: 0,
    },
    mdOffset: {
      type: Number,
      required: false,
      default: 0,
    },
    smOffset: {
      type: Number,
      required: false,
      default: 0,
    },
    xsOffset: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  computed: {
    classes() {
      const klass = {};
      if (this.lg) {
        klass[`col-lg-${this.lg}`] = true;
        if (this.lgOffset) {
          klass[`col-lg-offset-${this.lgOffset}`] = true;
        }
      }
      if (this.md) {
        klass[`col-md-${this.md}`] = true;
        if (this.mdOffset) {
          klass[`col-md-offset-${this.mdOffset}`] = true;
        }
      }
      if (this.sm) {
        klass[`col-sm-${this.sm}`] = true;
        if (this.smOffset) {
          klass[`col-sm-offset-${this.smOffset}`] = true;
        }
      }
      if (this.xs) {
        klass[`col-xs-${this.xs}`] = true;
        if (this.xsOffset) {
          klass[`col-xs-offset-${this.xsOffset}`] = true;
        }
      }
      return klass;
    },
  },
};
</script>
