export const AUTH = 'UPDATE_AUTH';
export const CALL = 'UPDATE_CALL';
export const DOWNTIME = 'UPDATE_DOWNTIME';
export const ENVIRONMENT = 'UPDATE_ENVIRONMENT';
export const FILE = 'UPDATE_FILE';
export const REPORT = 'UPDATE_REPORT';
export const SCHEDULE = 'UPDATE_SCHEDULE';
export const SUBSCRIPTION = 'UPDATE_SUBSCRIPTION';
export const TOKEN = 'UPDATE_TOKEN';
export const USER = 'UPDATE_USER';
export const WORKFLOW = 'UPDATE_WORKFLOW';
