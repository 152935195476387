import { STATE, ALL, STATUS, init } from './states';
import { needed, loaded, listOf, getById } from './getters';
import {
  needed as neededFn,
  loaded as loadedFn,
  listOf as listOfFn,
  getById as getByIdFn,
} from './getter-fns';
import { saveList, saveItem } from './mutations';
import { saveList as saveListFn, saveItem as saveItemFn } from './mutation-fns';
import { list, get, create, update, remove } from './actions';
import {
  list as listFn,
  get as getFn,
  create as createFn,
  update as updateFn,
  remove as removeFn,
} from './action-fns';

const states = Object.freeze({
  STATE,
  ALL,
  STATUS,
  init,
});

const getters = Object.freeze({
  needed,
  loaded,
  listOf,
  getById,
});

const getterFns = Object.freeze({
  needed: neededFn,
  loaded: loadedFn,
  listOf: listOfFn,
  getById: getByIdFn,
});

const mutations = Object.freeze({
  saveList,
  saveItem,
});

const mutationFns = Object.freeze({
  saveList: saveListFn,
  saveItem: saveItemFn,
});

const actions = Object.freeze({
  list,
  get,
  create,
  update,
  remove,
});

const actionFns = Object.freeze({
  list: listFn,
  get: getFn,
  create: createFn,
  update: updateFn,
  remove: removeFn,
});

export { states, getters, getterFns, mutations, mutationFns, actions, actionFns };
