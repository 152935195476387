import _get from 'lodash/get';
import _isFunction from 'lodash/isFunction';
import { STATE, ALL, getPath } from './state';
import { APImFetch, APImError } from './fetch';
import { STORE_ERROR } from './mutation-types';

const fetcher = new APImFetch();

async function handleException(except) {
  const error = { exception: except.toString() };

  console.error(except, error);
  try {
    if (except instanceof APImError) {
      error.message = except.message;
      error.status = except.response.status;
      error.statusText = except.response.statusText;
      error.body = await except.response.json();
    }
  } catch (ex) {
    console.error(ex);
  }

  return error;
}

function actionFetch(mutation, funcName, onDataCb) {
  // console.assert(mutation, 'no such mutation', mutation, funcName, onDataCb);
  // console.assert(funcName, 'no such funcName', mutation, funcName, onDataCb);
  return async function _fetchAction({ commit }, params) {
    // console.debug('actionFetch ', funcName);
    commit(mutation, { status: STATE.PENDING, params });
    // try {
    const resp = await fetcher[funcName](params).catch(async (except) => {
      if (except.name !== 'AbortError') {
        const error = await handleException(except);
        commit(mutation, { status: STATE.FAILED });
        commit(STORE_ERROR, { name: funcName, error });
      }
    });
    // console.log('actionFetch resp', resp);
    const data = _isFunction(onDataCb) ? onDataCb(resp) : resp;
    // console.log('actionFetch data', data);
    commit(mutation, { status: resp ? STATE.SUCCESS : STATE.FAILED, params, data });
    return data;
    // } catch (except) {
    //   const error = await handleException(except);

    //   commit(mutation, {
    //     status: STATE.FAILED,
    //     params,
    //     error,
    //   });

    //   return null;
    // }
  };
}

function actionFetchList(mutation, funcName) {
  // console.assert(mutation, 'no such mutation', mutation, funcName);
  // console.assert(funcName, 'no such funcName', mutation, funcName);
  return async function _fetchAction({ commit }, params) {
    // console.debug('actionFetchList ', funcName);
    commit(mutation, { status: STATE.PENDING, params });
    // try {
    const success = await fetcher[funcName]({
      ...params,
      pageCb: (resp) => {
        const data = resp.results;
        commit(mutation, { status: STATE.SUCCESS, params, data });
      },
    }).catch(async (except) => {
      if (except.name !== 'AbortError') {
        const error = await handleException(except);
        commit(mutation, { status: STATE.FAILED, error });
        commit(STORE_ERROR, { name: funcName, error });
      }
    });
    if (success) {
      commit(mutation, { status: STATE.SUCCESS, params, data: [] });
    }
    // } catch (error) {
    //   commit(mutation, {
    //     status: STATE.FAILED,
    //     params,
    //     error,
    //   });
    // }
  };
}

function actionRequireObjects(statePathFn, action, getterName) {
  // assert(statePathFn, 'no such statePathFn', statePathFn, action, getterName);
  // console.assert(action, 'no such action', statePathFn, action, getterName);
  return async function _requireObjects({ state, dispatch, getters }, params) {
    const allKeyPath = getPath(statePathFn, () => ALL, params);
    const currState = _get(state.loading, allKeyPath, STATE.NOT_SET);
    // console.log('Require', allKeyPath, currState);
    if (currState !== STATE.SUCCESS && currState !== STATE.PENDING) {
      // console.log('Fetching... ', action);
      await dispatch(action, params);
    }
    return _isFunction(getters[getterName]) ? getters[getterName](params) : getters[getterName];
  };
}

function actionRequireObjectById(statePathFn, stateKeyFn, action, getterName) {
  // console.assert(statePathFn, 'no such statePathFn', statePathFn, action, getterName);
  // console.assert(action, 'no such action', statePathFn, action, getterName);
  return async function _requireObjectById({ state, dispatch, getters }, params) {
    // console.assert(params);

    const itemKeyPath = getPath(statePathFn, stateKeyFn, params);
    const currItemState = _get(state.loading, itemKeyPath, STATE.NOT_SET);

    //  console.debug('Require by ID', itemKeyPath, currItemState);
    if (currItemState !== STATE.SUCCESS && currItemState !== STATE.PENDING) {
      //  console.debug('Fetching... ', action);
      await dispatch(action, params);
    }
    return getters[getterName](params);
  };
}

export {
  fetcher,
  handleException,
  actionFetch,
  actionFetchList,
  actionRequireObjects,
  actionRequireObjectById,
};
