import _toUpper from 'lodash/toUpper';
import _snakeCase from 'lodash/snakeCase';
import queryString from 'query-string';

const routes = {
  params: {},
  account_settings_main: {
    methods: null,
    route: '/account-settings/main/__CATALOG__',
    variables: ['catalog'],
  },
  account_settings_variables: {
    methods: null,
    route: '/account-settings/variables/__WORKSPACE__',
    variables: ['workspace'],
  },
  agents_create: {
    methods: ['POST'],
    route: '/agents/create',
    variables: [],
  },
  agents_delete: {
    methods: null,
    route: '/agents/agent/__AGENT_KEY_STR__/delete',
    variables: ['agent_key_str'],
  },
  agents_edit: {
    methods: null,
    route: '/agents/agent/__AGENT_KEY_STR__/edit',
    variables: ['agent_key_str'],
  },
  agents_home: {
    methods: null,
    route: '/agents/',
    variables: [],
  },
  agents_update_json: {
    methods: null,
    route: '/agents/agent/__AGENT_KEY_STR__/update.json',
    variables: ['agent_key_str'],
  },
  agents_view: {
    methods: null,
    route: '/agents/agent/__AGENT_KEY_STR__/',
    variables: ['agent_key_str'],
  },
  contact: {
    methods: null,
    route: '/contact/',
    variables: [],
  },
  create_region_test_run: {
    methods: ['POST'],
    route: '/tests/test/__CALL_ID__/regional_test_run',
    variables: ['callId'],
  },
  email_changed_check: {
    methods: null,
    route: '/change-email/__USER_ID__/__ENCODED_EMAIL__/__TOKEN__',
    variables: ['user_id', 'encoded_email', 'token'],
  },
  email_view: {
    methods: null,
    route: '/email/__EMAIL_KEY_STR__',
    variables: ['email_key_str'],
  },
  legacy_api_stats_passfail: {
    methods: ['GET'],
    route: '/api/2/calls/__CALL_ID__/stats/legacy/passfail',
    variables: ['callId'],
  },
  legacy_api_stats_passfail_range: {
    methods: ['GET'],
    route: '/api/2/calls/__CALL_ID__/stats/legacy/passfail_range',
    variables: ['callId'],
  },
  login: {
    methods: null,
    route: '/login/',
    variables: [],
  },
  logout: {
    methods: null,
    route: '/logout/',
    variables: [],
  },
  mailer_collate_insights: {
    methods: null,
    route: '/mailer/collate-insights/__USER_KEY_STR__',
    variables: ['user_key_str'],
  },
  mailer_send_email: {
    methods: null,
    route: '/mailer/send',
    variables: [],
  },
  mailer_send_email_simple: {
    methods: null,
    route: '/mailer/simple',
    variables: [],
  },
  migrate_home: {
    methods: null,
    route: '/migrate/__KIND__',
    variables: ['kind'],
  },
  notifications_home: {
    methods: null,
    route: '/notifications/',
    variables: [],
  },
  notifications_org: {
    methods: null,
    route: '/notifications/organization/__ORG_ID__/',
    variables: ['org_id'],
  },
  notifications_update_json: {
    methods: null,
    route: '/notifications/__KEY_STR__/update.json',
    variables: ['key_str'],
  },
  notifications_view: {
    methods: null,
    route: '/notifications/__KEY_STR__/',
    variables: ['key_str'],
  },
  organizations_create: {
    methods: null,
    route: '/organizations/edit/',
    variables: [],
  },
  organizations_edit: {
    methods: null,
    route: '/organizations/edit/__ORG_ID__',
    variables: ['org_id'],
  },
  organizations_home: {
    methods: null,
    route: '/organizations/',
    variables: [],
  },
  password_reset: {
    methods: null,
    route: '/password-reset/',
    variables: [],
  },
  password_reset_check: {
    methods: null,
    route: '/password-reset/__USER_ID__/__TOKEN__',
    variables: ['user_id', 'token'],
  },
  privacy: {
    methods: null,
    route: 'http%3A//apimetrics.io/privacy/',
    variables: [],
  },
  profile_api_key: {
    methods: null,
    route: '/settings/api-key',
    variables: [],
  },
  profile_disable_2fa: {
    methods: null,
    route: '/settings/disable-2fa',
    variables: [],
  },
  profile_enable_2fa: {
    methods: null,
    route: '/settings/enable-2fa',
    variables: [],
  },
  profile_extend_trial: {
    methods: null,
    route: '/extend-trial',
    variables: [],
  },
  profile_gen_key: {
    methods: null,
    route: '/settings/generate-api-key',
    variables: [],
  },
  public_map: {
    methods: null,
    route: '/public/map',
    variables: [],
  },
  recurly_webhook: {
    methods: null,
    route: '/remote-api/1/recurly',
    variables: [],
  },
  remote_agent_register: {
    methods: ['POST'],
    route: '/remote-api/1/agent/register',
    variables: [],
  },
  remote_api: {
    methods: ['POST'],
    route: '/remote-api/1/test/__CALL_ID__/',
    variables: ['callId'],
  },
  reports_create: {
    methods: null,
    route: '/reports/create',
    variables: [],
  },
  reports_edit: {
    methods: null,
    route: '/reports/__REPORT_KEY_STR__/edit',
    variables: ['report_key_str'],
  },
  reports_public: {
    methods: null,
    route: '/reports/__REPORT_KEY_STR__/public',
    variables: ['report_key_str'],
  },
  reports_view: {
    methods: null,
    route: '/reports/__REPORT_KEY_STR__/',
    variables: ['report_key_str'],
  },
  reports_view_csv: {
    methods: null,
    route: '/reports/__REPORT_KEY_STR__/csv',
    variables: ['report_key_str'],
  },
  reports_view_group_csv: {
    methods: null,
    route: '/reports/__REPORT_KEY_STR__/__GROUP_ID__/csv',
    variables: ['report_key_str', 'group_id'],
  },
  reports_view_group_location_csv: {
    methods: null,
    route: '/reports/__REPORT_KEY_STR__/__GROUP_ID__/locations/csv',
    variables: ['report_key_str', 'group_id'],
  },
  reports_view_location_csv: {
    methods: null,
    route: '/reports/__REPORT_KEY_STR__/locations/csv',
    variables: ['report_key_str'],
  },
  service_create: {
    methods: ['GET'],
    route: '/services/create',
    variables: [],
  },
  services_change_owner: {
    methods: ['POST'],
    route: '/services/__AUTH_ID__/change_ownership/',
    variables: ['authId'],
  },
  services_delete: {
    methods: null,
    route: '/services/__AUTH_ID__/delete',
    variables: ['authId'],
  },
  services_edit: {
    methods: null,
    route: '/services/__AUTH_ID__/edit',
    variables: ['authId'],
  },
  services_view: {
    methods: null,
    route: '/services/__AUTH_ID__/',
    variables: ['authId'],
  },
  subscriptions_view: {
    methods: null,
    route: '/subscriptions/',
    variables: [],
  },
  summary_edit: {
    methods: null,
    route: '/summary/edit',
    variables: [],
  },
  summary_home: {
    methods: null,
    route: '/summary/',
    variables: [],
  },
  terms: {
    methods: null,
    route: 'http%3A//apimetrics.io/subscription/',
    variables: [],
  },
  test_run_results_list: {
    methods: null,
    route: '/runs/run/__TEST_RUN_KEY_STR__/results/',
    variables: ['test_run_key_str'],
  },
  test_run_results_list_csv: {
    methods: null,
    route: '/runs/run/__TEST_RUN_KEY_STR__/results/csv',
    variables: ['test_run_key_str'],
  },
  tests_alerts: {
    methods: null,
    route: '/tests/test/__CALL_ID__/alerts',
    variables: ['callId'],
  },
  tests_conditions: {
    methods: null,
    route: '/tests/test/__CALL_ID__/conditions',
    variables: ['callId'],
  },
  tests_delete: {
    methods: null,
    route: '/tests/test/__CALL_ID__/delete',
    variables: ['callId'],
  },
  tests_email_on_fail_json: {
    methods: null,
    route: '/api/2/calls/__CALL_ID__/email_on_fail.json',
    variables: ['callId'],
  },
  tests_list: {
    methods: null,
    route: '/tests/service/__AUTH_ID__/',
    variables: ['authId'],
  },
  tests_list_picker: {
    methods: null,
    route: '/tests/tests-list-picker',
    variables: [],
  },
  tests_list_subscribed: {
    methods: null,
    route: '/tests/subscribed/',
    variables: [],
  },
  tokens_create: {
    methods: ['GET'],
    route: '/tokens/service/__AUTH_ID__/create',
    variables: ['authId'],
  },
  tokens_create_post: {
    methods: ['POST'],
    route: '/tokens/service/__AUTH_ID__/create',
    variables: ['authId'],
  },
  tokens_delete: {
    methods: null,
    route: '/tokens/token/__TOKEN_KEY_STR__/delete',
    variables: ['token_key_str'],
  },
  tokens_edit: {
    methods: null,
    route: '/tokens/token/__TOKEN_KEY_STR__/',
    variables: ['token_key_str'],
  },
  tokens_home: {
    methods: null,
    route: '/tokens/',
    variables: [],
  },
  tokens_list: {
    methods: null,
    route: '/tokens/service/__AUTH_ID__/',
    variables: ['authId'],
  },
  tokens_list_json: {
    methods: null,
    route: '/tokens/service/__AUTH_ID__/list.json',
    variables: ['authId'],
  },
  tokens_wizard_callback_continue: {
    methods: null,
    route: '/tokens/callback/continue',
    variables: [],
  },
  tokens_wizard_start: {
    methods: null,
    route: '/tokens/service/__AUTH_ID__/wizard',
    variables: ['authId'],
  },
  tools_reports_get: {
    methods: null,
    route: '/tools/1/reports/__REPORT_KEY_STR__/',
    variables: ['report_key_str'],
  },
  tools_reports_script: {
    methods: null,
    route: '/tools/1/reports/__REPORT_KEY_STR__/script',
    variables: ['report_key_str'],
  },
  tos: {
    methods: null,
    route: 'http%3A//apimetrics.io/tos/',
    variables: [],
  },
  user_edit: {
    methods: null,
    route: '/admin/users/__USER_ID__/',
    variables: ['user_id'],
  },
  user_list: {
    methods: null,
    route: '/admin/users/',
    variables: [],
  },
  user_move_all: {
    methods: null,
    route: '/admin/move_all/__USER_KEY_STR__',
    variables: ['user_key_str'],
  },
  users_edit: {
    methods: null,
    route: '/users/__USER_KEY_STR__/',
    variables: ['user_key_str'],
  },
  users_home: {
    methods: null,
    route: '/users/',
    variables: [],
  },
  users_invite: {
    methods: null,
    route: '/users/invite',
    variables: [],
  },
  users_list_org: {
    methods: null,
    route: '/users/org/__ORG__/',
    variables: ['org'],
  },
  welcome_map: {
    methods: null,
    route: '/welcome/map',
    variables: [],
  },
  widget_demo: {
    methods: null,
    route: '/widgets/demo',
    variables: [],
  },
  widget_demo_edit: {
    methods: null,
    route: '/widgets/demo/edit',
    variables: [],
  },
  widget_notifications: {
    methods: ['GET'],
    route: '/widgets/notifications/',
    variables: [],
  },
  widget_notifications_edit: {
    methods: ['GET'],
    route: '/widgets/notifications/edit',
    variables: [],
  },
  widget_org_results_edit: {
    methods: ['GET'],
    route: '/widgets/results/org/__ORG_ID__/edit',
    variables: ['org_id'],
  },
  widget_org_stats_fail_pie_edit: {
    methods: ['GET'],
    route: '/widgets/stats/fail_pie/org/__ORG_ID__/edit',
    variables: ['org_id'],
  },
  widget_org_stats_latency_graph_edit: {
    methods: ['GET'],
    route: '/widgets/stats/latency_graph/org/__ORG_ID__/edit',
    variables: ['org_id'],
  },
  widget_org_stats_latency_histo_edit: {
    methods: ['GET'],
    route: '/widgets/stats/latency_histo/org/__ORG_ID__/edit',
    variables: ['org_id'],
  },
  widget_org_stats_passfail_histo_edit: {
    methods: ['GET'],
    route: '/widgets/stats/passfail_histo/org/__ORG_ID__/edit',
    variables: ['org_id'],
  },
  widget_org_stats_text_edit: {
    methods: ['GET'],
    route: '/widgets/stats/text/org/__ORG_ID__/edit',
    variables: ['org_id'],
  },
  widget_report: {
    methods: ['GET'],
    route: '/widgets/report/__REPORT_KEY_STR__/',
    variables: ['report_key_str'],
  },
  widget_report_edit: {
    methods: ['GET'],
    route: '/widgets/report/edit',
    variables: [],
  },
  widget_report_group_picker: {
    methods: ['GET'],
    route: '/widgets/report/__REPORT_KEY_STR__/groups',
    variables: ['report_key_str'],
  },
  widget_report_latency_graph_edit: {
    methods: ['GET'],
    route: '/widgets/report_latency_graph/edit',
    variables: [],
  },
  widget_reports_latency_graph: {
    methods: null,
    route: '/widgets/report/__REPORT_KEY_STR__/latency',
    variables: ['report_key_str'],
  },
  widget_results: {
    methods: ['GET'],
    route: '/widgets/results',
    variables: [],
  },
  widget_results_edit: {
    methods: ['GET'],
    route: '/widgets/results/edit',
    variables: [],
  },
  widget_services: {
    methods: ['GET'],
    route: '/widgets/services',
    variables: [],
  },
  widget_services_edit: {
    methods: ['GET'],
    route: '/widgets/services/edit',
    variables: [],
  },
  widget_stats_fail_pie: {
    methods: ['GET'],
    route: '/widgets/stats/__CALL_ID__/fail_pie',
    variables: ['callId'],
  },
  widget_stats_fail_pie_edit: {
    methods: ['GET'],
    route: '/widgets/stats/fail_pie/edit',
    variables: [],
  },
  widget_stats_latency_cumulative_heatmap: {
    methods: ['GET'],
    route: '/widgets/stats/__CALL_ID__/latency_cumulative_heatmap',
    variables: ['callId'],
  },
  widget_stats_latency_cumulative_heatmap_edit: {
    methods: ['GET'],
    route: '/widgets/stats/latency_cumulative_heatmap/edit',
    variables: [],
  },
  widget_stats_latency_graph: {
    methods: ['GET'],
    route: '/widgets/stats/__CALL_ID__/latency_graph',
    variables: ['callId'],
  },
  widget_stats_latency_graph_edit: {
    methods: ['GET'],
    route: '/widgets/stats/latency_graph/edit',
    variables: [],
  },
  widget_stats_latency_histo: {
    methods: ['GET'],
    route: '/widgets/stats/__CALL_ID__/latency_histo',
    variables: ['callId'],
  },
  widget_stats_latency_histo_edit: {
    methods: ['GET'],
    route: '/widgets/stats/latency_histo/edit',
    variables: [],
  },
  widget_stats_latency_sequential_heatmap: {
    methods: ['GET'],
    route: '/widgets/stats/__CALL_ID__/latency_sequential_heatmap',
    variables: ['callId'],
  },
  widget_stats_latency_sequential_heatmap_edit: {
    methods: ['GET'],
    route: '/widgets/stats/latency_sequential_heatmap/edit',
    variables: [],
  },
  widget_stats_map: {
    methods: ['GET'],
    route: '/widgets/stats/__CALL_ID__/map',
    variables: ['callId'],
  },
  widget_stats_passfail_block: {
    methods: ['GET'],
    route: '/widgets/stats/__CALL_ID__/passfail_block',
    variables: ['callId'],
  },
  widget_stats_passfail_block_edit: {
    methods: ['GET'],
    route: '/widgets/stats/passfail_block/edit',
    variables: [],
  },
  widget_stats_passfail_histo: {
    methods: ['GET'],
    route: '/widgets/stats/__CALL_ID__/passfail_histo',
    variables: ['callId'],
  },
  widget_stats_passfail_histo_edit: {
    methods: ['GET'],
    route: '/widgets/stats/passfail_histo/edit',
    variables: [],
  },
  widget_stats_response_size_graph: {
    methods: ['GET'],
    route: '/widgets/stats/__CALL_ID__/response_size_graph',
    variables: ['callId'],
  },
  widget_stats_response_size_graph_edit: {
    methods: ['GET'],
    route: '/widgets/stats/response_size_graph/edit',
    variables: [],
  },
  widget_stats_text: {
    methods: ['GET'],
    route: '/widgets/stats/__CALL_ID__/text',
    variables: ['callId'],
  },
  widget_stats_text_edit: {
    methods: ['GET'],
    route: '/widgets/stats/text/edit',
    variables: [],
  },
  widget_test_results: {
    methods: ['GET'],
    route: '/widgets/results/__CALL_ID__/',
    variables: ['callId'],
  },
  widget_test_run_results: {
    methods: ['GET'],
    route: '/widgets/runs/__TEST_RUN_KEY_STR__/results/',
    variables: ['test_run_key_str'],
  },
  widget_test_run_results_edit: {
    methods: ['GET'],
    route: '/widgets/runs/results/edit',
    variables: [],
  },
  widget_tests: {
    methods: ['GET'],
    route: '/widgets/tests',
    variables: [],
  },
  widget_tests_edit: {
    methods: ['GET'],
    route: '/widgets/tests/edit',
    variables: [],
  },
  widget_tokens: {
    methods: ['GET'],
    route: '/widgets/tokens/',
    variables: [],
  },
  widget_tokens_edit: {
    methods: ['GET'],
    route: '/widgets/tokens/edit',
    variables: [],
  },
  wizard_start: {
    methods: null,
    route: '/tests/create',
    variables: [],
  },
};

const nonVueRoutes = {
  namespaced: true,
  state: routes,
  getters: {
    getPath:
      (state, getters, rootState) =>
      ({ name, variables, params }) => {
        const info = state[name];
        if (!info) {
          const message = `getPath: No such route ${name}`;
          throw message;
        }
        let path = `${info.route}`;
        info.variables.forEach((varName) => {
          const value = variables[varName];
          if (value === undefined) {
            const message = `For route ${name}, no variable ${varName} specified!`;
            throw message;
          }
          const pathVar = `__${_toUpper(_snakeCase(varName))}__`;
          path = path.split(pathVar).join(value);
        });

        const { projectId } = rootState.account2;
        if (params || state.params || projectId) {
          const query = queryString.stringify(
            {
              project_key_str: projectId,
              ...state.params,
              ...(params || {}),
            },
            { arrayFormat: 'none' },
          );
          if (query) {
            return `${path}?${query}`;
          }
        }
        return path;
      },
  },
};

export default nonVueRoutes;
