import _camelCase from 'lodash/camelCase';
import _toUpper from 'lodash/toUpper';
import _snakeCase from 'lodash/snakeCase';
import { APImInterface } from './fetch';
import { STATE, ALL } from './state';
import * as mutations from './mutation-types';
import {
  actionFetchList,
  actionFetch,
  actionRequireObjects,
  actionRequireObjectById,
} from './actions';
import { getterList, getterById, getLoadState } from './getters';
import { mutationSaveList, mutationSaveById } from './mutations';

const STANDARD_TYPES = [
  'auth',
  'call',
  'downtime',
  'environment',
  'file',
  'report',
  'schedule',
  'subscription',
  'token',
  // 'user',
  'workflow',
];

/*
 * Given a key (e.g. "Object"), generates two getters:
 *   - listObjects - gettings a list of things
 *   - getObject - for getting one thing by primary key
 */
function standardGetters({ key, map = {}, pluralKey = `${key}s` }) {
  const out = map;
  const listKey = _camelCase(`list_${pluralKey}`);
  const getKey = _camelCase(`get_${key}`);
  const hasList = _camelCase(`state_${pluralKey}`);
  const hasKey = _camelCase(`state_${key}`);
  out[listKey] = getterList(pluralKey);
  out[getKey] = getterById(pluralKey, 'id');
  out[hasList] = getLoadState(pluralKey, () => ALL);
  out[hasKey] = getLoadState(pluralKey, 'id');
  return out;
}

/*
 * Given a key (e.g. "Object"), generates mutations:
 *   - mutations.STORE_OBJECTS - to handle new objects (from API)
 *   - mutations.SAVE_OBJECT - to handle one new object (from API)
 */
function standardMutations({ key, pluralKey = `${key}s`, map = {} }) {
  const out = map;
  const mutStoreKey = _toUpper(_snakeCase(`store_${pluralKey}`));
  out[mutations[mutStoreKey]] = mutationSaveList(pluralKey);
  const mutSaveKey = _toUpper(_snakeCase(`save_${key}`));
  out[mutations[mutSaveKey]] = mutationSaveById(pluralKey, 'id');
  return out;
}

/*
 * Given a key (e.g. "Object"), generates actions:
 *   - fetchObjects - to trigger API call to query/list
 *   - createObject - to trigger API call to create
 *   - fetchObject - to trigger API call to read
 *   - updateObject - to trigger API call to update
 *   - deleteObject - to trigger API call to delete
 *   - requireObjects - to only trigger a query if necessary
 *   - requireObject - to only trigger a read if necessary
 */
function standardActions({ key, map = {}, pluralKey = `${key}s` }) {
  const out = map;

  const mutStoreKey = _toUpper(_snakeCase(`store_${pluralKey}`));
  const mutSaveKey = _toUpper(_snakeCase(`save_${key}`));

  const queryKey = _camelCase(`fetch_${pluralKey}`);
  const createKey = _camelCase(`create_${key}`);
  const readKey = _camelCase(`fetch_${key}`);
  const updateKey = _camelCase(`update_${key}`);
  const deleteKey = _camelCase(`delete_${key}`);
  const requireKeys = _camelCase(`require_${pluralKey}`);
  const requireKey = _camelCase(`require_${key}`);

  const apiQueryKey = _toUpper(`${pluralKey}`);
  const apiKey = _toUpper(key);

  const listKey = _camelCase(`list_${pluralKey}`);
  const getKey = _camelCase(`get_${key}`);

  out[queryKey] = actionFetchList(mutations[mutStoreKey], APImInterface.QUERY[apiQueryKey]);
  out[createKey] = actionFetch(mutations[mutSaveKey], APImInterface.CREATE[apiKey]);
  out[readKey] = actionFetch(mutations[mutSaveKey], APImInterface.READ[apiKey]);
  out[updateKey] = actionFetch(mutations[mutSaveKey], APImInterface.UPDATE[apiKey]);
  out[deleteKey] = actionFetch(
    mutations[mutSaveKey],
    APImInterface.DELETE[apiKey],
    () => undefined,
  );
  out[requireKeys] = actionRequireObjects(pluralKey, queryKey, listKey);
  out[requireKey] = actionRequireObjectById(pluralKey, 'id', readKey, getKey);
  return out;
}

const STANDARD = {
  state: STANDARD_TYPES.reduce((mapIn, key) => {
    const map = mapIn;
    map[`${key}s`] = {};
    return map;
  }, {}),
  loadingState: STANDARD_TYPES.reduce((mapIn, key) => {
    const map = mapIn;
    map[`${key}s`] = { [ALL]: STATE.NOT_SET };
    return map;
  }, {}),
  getters: STANDARD_TYPES.reduce((map, key) => standardGetters({ key, map }), {}),
  mutations: STANDARD_TYPES.reduce((map, key) => standardMutations({ key, map }), {}),
  actions: STANDARD_TYPES.reduce((map, key) => standardActions({ key, map }), {}),
};

export { standardGetters, standardMutations, standardActions, STANDARD };
