import _get from 'lodash/get';
import _sortBy from 'lodash/sortBy';
import Vue from 'vue';
import { make } from 'vuex-pathify';
import Bugsnag from '@bugsnag/js';
import { fetcher, STATE } from './apim-api';

const AUDIENCE = process.env.VUE_APP_AUTH0_AUDIENCE;

// const example = {
//   results: [
//     {
//       access_level: 'OWNER',
//       account_id: 'auth0|5e7abce1cee9ae0c7b457f0b',
//       last_update: '2020-03-25T02:07:45.066528Z',
//       project: {
//         created: '2020-03-25T02:07:44.992126Z',
//         org_id: '',
//         name: 'test8',
//         id: 'agxkZXZ-dmlhdGVzdHNyEQsSBFVzZXIYgICAgIDAugsM',
//         last_update: '2020-03-25T02:07:44.992142Z',
//       },
//       id: 'agxkZXZ-dmlhdGVzdHNyGwsSDkFjY291bnRQcm9qZWN0GICAgICAwPoIDA',
//       organization: null,
//       created: '2020-03-25T02:07:45.066523Z',
//       account_email: 'test8@njdenny.com',
//     },
//     {
//       project: {
//         created: '2019-02-15T22:28:03.051016Z',
//         org_id: 'apimetrics',
//         name: 'nick222 too aswell',
//         id: 'agxkZXZ-dmlhdGVzdHNyEQsSBFVzZXIYgICAgICA0AoM',
//         last_update: '2020-03-03T19:14:08.465359Z',
//       },
//       access_level: 'EDITOR',
//       role_id: 'DEFAULT',
//       organization: {
//         id: 'apimetrics',
//         last_update: '2020-06-09T21:47:29.156510Z',
//         enforce_2fa: false,
//         kms_enabled: true,
//         billing_admin_id: null,
//         created: '2014-11-26T00:13:41.037875Z',
//         name: 'APImetrics \u751f\u6210',
//         subscription_level: 'enterprise',
//       },
//       last_update: '2020-04-24T21:20:13.847429Z',
//       id: 'agxkZXZ-dmlhdGVzdHNyGAsSC1Byb2plY3RSb2xlGICAgICAwK0JDA',
//       created: '2020-04-24T21:20:13.847420Z',
//     },
//     {
//       project: {
//         created: '2016-10-20T22:20:24.629889Z',
//         org_id: 'apimetrics',
//         name: 'Foo',
//         id: 'agxkZXZ-dmlhdGVzdHNyEQsSBFVzZXIYgICAgNDmlgoM',
//         last_update: '2020-04-03T00:26:54.755631Z',
//       },
//       access_level: 'VIEWER',
//       role_id: 'DEFAULT',
//       organization: {
//         id: 'apimetrics',
//         last_update: '2020-06-09T21:47:29.156510Z',
//         enforce_2fa: false,
//         kms_enabled: true,
//         billing_admin_id: null,
//         created: '2014-11-26T00:13:41.037875Z',
//         name: 'APImetrics \u751f\u6210',
//         subscription_level: 'enterprise',
//       },
//       last_update: '2020-04-10T22:49:15.397957Z',
//       id: 'agxkZXZ-dmlhdGVzdHNyGAsSC1Byb2plY3RSb2xlGICAgICAwIUIDA',
//       created: '2020-04-10T22:49:15.397945Z',
//     },
//     {
//       project: {
//         id: 'agxkZXZ-dmlhdGVzdHNyEQsSBFVzZXIYgICAgMDBugoM',
//         last_update: '2019-10-30T17:36:48.371316Z',
//         email: 'test@apimetrics.com',
//         org_id: 'apimetrics',
//         created: '2015-06-02T22:32:31.589320Z',
//         name: 'APImetrics User',
//       },
//       access_level: 'EDITOR',
//       role_id: 'VIEWER_GANG',
//       organization: {
//         id: 'apimetrics',
//         last_update: '2020-06-09T21:47:29.156510Z',
//         enforce_2fa: false,
//         kms_enabled: true,
//         billing_admin_id: null,
//         created: '2014-11-26T00:13:41.037875Z',
//         name: 'APImetrics \u751f\u6210',
//         subscription_level: 'enterprise',
//       },
//       last_update: '2020-02-12T02:52:18.840700Z',
//       id: 'agxkZXZ-dmlhdGVzdHNyGAsSC1Byb2plY3RSb2xlGICAgICAwJgJDA',
//       created: '2020-02-12T02:52:18.840691Z',
//     },
//     {
//       project: {
//         created: '2020-01-04T03:05:42.801201Z',
//         org_id: 'apimetrics',
//         name: 'nick226',
//         id: 'agxkZXZ-dmlhdGVzdHNyEQsSBFVzZXIYgICAgICA3woM',
//         last_update: '2020-02-14T02:11:32.453874Z',
//       },
//       access_level: 'EDITOR',
//       role_id: 'DEFAULT',
//       organization: {
//         id: 'apimetrics',
//         last_update: '2020-06-09T21:47:29.156510Z',
//         enforce_2fa: false,
//         kms_enabled: true,
//         billing_admin_id: null,
//         created: '2014-11-26T00:13:41.037875Z',
//         name: 'APImetrics \u751f\u6210',
//         subscription_level: 'enterprise',
//       },
//       last_update: '2020-04-24T21:20:10.022559Z',
//       id: 'agxkZXZ-dmlhdGVzdHNyGAsSC1Byb2plY3RSb2xlGICAgICAwK0KDA',
//       created: '2020-04-24T21:20:10.022549Z',
//     },
//     {
//       project: {
//         created: '2020-02-07T03:24:27.427589Z',
//         org_id: 'apimetrics',
//         name: 'New Project',
//         id: 'agxkZXZ-dmlhdGVzdHNyEQsSBFVzZXIYgICAgIDA6AsM',
//         last_update: '2020-07-02T00:40:01.101448Z',
//       },
//       access_level: 'VIEWER',
//       role_id: 'DEFAULT',
//       organization: {
//         id: 'apimetrics',
//         last_update: '2020-06-09T21:47:29.156510Z',
//         enforce_2fa: false,
//         kms_enabled: true,
//         billing_admin_id: null,
//         created: '2014-11-26T00:13:41.037875Z',
//         name: 'APImetrics \u751f\u6210',
//         subscription_level: 'enterprise',
//       },
//       last_update: '2020-04-10T22:49:17.266990Z',
//       id: 'agxkZXZ-dmlhdGVzdHNyGAsSC1Byb2plY3RSb2xlGICAgICAwIUKDA',
//       created: '2020-04-10T22:49:17.266983Z',
//     },
//     {
//       project: {
//         id: 'agxkZXZ-dmlhdGVzdHNyEQsSBFVzZXIYgICAgOCElgkM',
//         last_update: '2018-01-26T18:45:30.145892Z',
//         email: 'testc@example.com',
//         org_id: 'apimetrics',
//         created: '2015-12-14T20:13:02.869814Z',
//         name: 'Test',
//       },
//       access_level: 'VIEWER',
//       role_id: 'DEFAULT',
//       organization: {
//         id: 'apimetrics',
//         last_update: '2020-06-09T21:47:29.156510Z',
//         enforce_2fa: false,
//         kms_enabled: true,
//         billing_admin_id: null,
//         created: '2014-11-26T00:13:41.037875Z',
//         name: 'APImetrics \u751f\u6210',
//         subscription_level: 'enterprise',
//       },
//       last_update: '2020-04-10T22:49:20.420800Z',
//       id: 'agxkZXZ-dmlhdGVzdHNyGAsSC1Byb2plY3RSb2xlGICAgICAwIUJDA',
//       created: '2020-04-10T22:49:20.420794Z',
//     },
//     {
//       access_level: 'VIEWER',
//       account_id: 'auth0|5e7abce1cee9ae0c7b457f0b',
//       last_update: '2020-04-24T21:01:21.862407Z',
//       project: {
//         created: '2015-03-25T21:42:41.608148Z',
//         org_id: 'test',
//         name: 'test 6',
//         id: 'agxkZXZ-dmlhdGVzdHNyEQsSBFVzZXIYgICAgICF6AkM',
//         last_update: '2020-04-23T21:51:58.518411Z',
//       },
//       id: 'agxkZXZ-dmlhdGVzdHNyGwsSDkFjY291bnRQcm9qZWN0GICAgICAwK0IDA',
//       organization: {
//         id: 'test',
//         last_update: '2018-07-27T22:57:41.348687Z',
//         enforce_2fa: false,
//         kms_enabled: false,
//         billing_admin_id: 'agxkZXZ-dmlhdGVzdHNyEQsSBFVzZXIYgICAgICF6AkM',
//         created: '2014-11-26T00:13:41.288210Z',
//         name: 'Test Inc',
//         subscription_level: 'user',
//       },
//       created: '2020-04-24T21:01:21.862399Z',
//       account_email: 'test8@njdenny.com',
//     },
//   ],
//   meta: {
//     new_project: false,
//     current_project_id: null,
//     account_id: 'auth0|5e7abce1cee9ae0c7b457f0b',
//     verify_needed: false,
//     invites: [],
//     organizations: {
//       test: {
//         id: 'test',
//         enforce_2fa: false,
//         kms_enabled: false,
//         billing_project_id: 'agxkZXZ-dmlhdGVzdHNyEQsSBFVzZXIYgICAgICF6AkM',
//         name: 'Test Inc',
//         subscription_level: 'user',
//       },
//       apimetrics: {
//         id: 'apimetrics',
//         enforce_2fa: false,
//         kms_enabled: true,
//         billing_project_id: null,
//         name: 'APImetrics \u751f\u6210',
//         subscription_level: 'enterprise',
//       },
//     },
//     roles: {
//       apimetrics: [
//         'DEFAULT',
//         'ADMIN',
//         'VIEWER_GANG',
//       ],
//     },
//   },
// };

// Tracking helper funcs

function saveHubspotQuotaData(quotaInfo) {
  // eslint-disable-next-line no-underscore-dangle, no-multi-assign
  const hsq = (window._hsq = window._hsq || []);
  if (hsq) {
    const info = {};
    if (
      quotaInfo.trial_expiry_date &&
      (!quotaInfo.sub.level || quotaInfo.sub.level === 'NONE' || quotaInfo.sub.level === 'TRIAL')
    ) {
      info.trial_expires_at = new Date(quotaInfo.trial_expiry_date).getTime();
    } else {
      info.trial_expires_at = null;
    }
    hsq.push(['identify', info]);
  }
}

// function saveIntercomQuotaData(quotaInfo) {
//   // eslint-disable-next-line no-underscore-dangle, no-multi-assign
//   const info = {};
//   if (quotaInfo.trial_expiry_date && (
//     !quotaInfo.sub.level
//         || quotaInfo.sub.level === 'NONE'
//         || quotaInfo.sub.level === 'TRIAL'
//   )
//   ) {
//     info.trial_expires_at = quotaInfo.trial_expiry_date;
//   } else {
//     info.trial_expires_at = null;
//   }
//   window.Intercom('update', info);
// }

// function saveIntercomUserDataBasic(user) {
//   const data = {
//     email: user.email,
//     user_id: user.sub,
//     name: user.name || user.nickname,
//     avatar: { type: 'avatar', image_url: user.picture },
//     updated_at: user.updated_at,
//     is_apimetrics_user: true,
//   };
//   const permissions = (user[`${AUDIENCE}/permissions`] || []).slice().sort();
//   data.permissions = permissions.join(' ');
//   const orgIds = (user[`${AUDIENCE}/org_ids`] || []).slice().sort();
//   data.org_ids = orgIds.join(' ');
//   window.Intercom('update', data);
// }

// function saveIntercomUserData(user, projectId, orgId, projectCounts, ownedProjects) {
//   const data = {
//     email: user.email,
//     user_id: user.sub,
//     name: user.name || user.nickname,
//     avatar: { type: 'avatar', image_url: user.picture },
//     org_id: orgId,
//     updated_at: user.updated_at,
//     ...ownedProjects.map(({ id }) => `https://client.apimetrics.io/home?project_key_str=${id}`)
//       .reduce(
//         (soFar, val, idx) => ({ ...soFar, [`apimetrics_proj_${idx}`]: val }),
//         {},
//       ),
//     ...projectCounts,
//     is_apimetrics_user: true,
//     has_personal_projects: ownedProjects.length > 0,
//   };
//   if (projectId) {
//     data.apimetrics_home = `https://client.apimetrics.io/home?project_key_str=${projectId}`;
//   }
//   const permissions = (user[`${AUDIENCE}/permissions`] || []).slice().sort();
//   data.permissions = permissions.join(' ');
//   const orgIds = (user[`${AUDIENCE}/org_ids`] || []).slice().sort();
//   data.org_ids = orgIds.join(' ');
//   window.Intercom('update', data);
// }

function saveCrispUserData(user) {
  const { $crisp } = window;
  if ($crisp) {
    $crisp.push(['set', 'user:email', [user.email]]);
    $crisp.push(['set', 'user:nickname', [user.nickname]]);
    $crisp.push(['set', 'user:avatar', [user.picture]]);
  }
}

function saveHubspotUserDataBasic(user) {
  // eslint-disable-next-line no-underscore-dangle, no-multi-assign
  const hsq = (window._hsq = window._hsq || []);
  const data = {
    email: user.email,
    id: user.sub,
    nickname: user.nickname,
    picture: user.picture,
    has_signed_in: true,
    is_apimetrics_user: true,
  };
  if (user.created) {
    data.account_created_at = new Date(user.created).getTime();
  }
  if (user.additionalData) {
    data.nickname = user.additionalData.name;
    data.personal = user.additionalData.personalProject;
    data.company_name = user.additionalData.companyName;
    data.company_email = user.additionalData.workEmail;
    data.phone_number = user.additionalData.phoneNumber;
  }
  const permissions = (user[`${AUDIENCE}/permissions`] || []).slice().sort();
  data.permissions = permissions.join(' ');
  data.permissions_list = permissions.join(';');
  const orgIds = (user[`${AUDIENCE}/org_ids`] || []).slice().sort();
  data.org_ids = orgIds.join(' ');
  data.org_ids_list = orgIds.join(';');
  hsq.push(['identify', data]);
  hsq.push(['trackPageView']); // Force it to Hubspot
  // console.log('Hubspot user', JSON.stringify(user, null, 2));
}

function saveHubspotUserData(user, projectId, orgId, projectCounts, ownedProjects) {
  // eslint-disable-next-line no-underscore-dangle, no-multi-assign
  const hsq = (window._hsq = window._hsq || []);
  const data = {
    email: user.email,
    id: user.sub,
    nickname: user.nickname,
    picture: user.picture,
    ...ownedProjects
      .map(({ id }) => `https://client.apimetrics.io/home?project_key_str=${id}`)
      .reduce((soFar, val, idx) => ({ ...soFar, [`apimetrics_proj_${idx}`]: val }), {}),
    ...projectCounts,
    has_signed_in: true,
    is_apimetrics_user: true,
    has_personal_projects: ownedProjects.length > 0,
  };
  if (user.created) {
    data.account_created_at = new Date(user.created).getTime();
  }
  if (projectId) {
    data.apimetrics_home = `https://client.apimetrics.io/home?project_key_str=${projectId}`;
  }
  const permissions = (user[`${AUDIENCE}/permissions`] || []).slice().sort();
  data.permissions = permissions.join(' ');
  data.permissions_list = permissions.join(';');
  const orgIds = (user[`${AUDIENCE}/org_ids`] || []).slice().sort();
  data.org_ids = orgIds.join(' ');
  data.org_ids_list = orgIds.join(';');
  hsq.push(['identify', data]);
  hsq.push(['trackPageView']); // Force it to Hubspot
}

const state = {
  status: STATE.NOT_SET,
  pageTitles: {},

  accountId: null,
  orgId: null,
  projectId: null,
  nextProjectId: null,

  verifyNeeded: false,
  newProject: false,
  invites: [],
  organizations: {},
  projectsStatus: STATE.NOT_SET,
  projects: {},
  roles: {},
  quotaInfo: {},
  permissions: [],
  securityProfile: null,
  // example,
};

const mutations = make.mutations(state);

const actions = {
  setPageTitle({ state: { pageTitles }, commit }, { page, title, breadcrumb = null }) {
    commit('SET_PAGE_TITLES', { ...pageTitles, [page]: { title, breadcrumb } });
  },

  async fetchAccountProjects({ state: { status }, dispatch, commit }, { noProjectOk = false }) {
    // eslint-disable-next-line no-underscore-dangle
    const { $auth } = this._vm;
    const auth = $auth.user;

    // console.log('fetchAccountProjects');
    if (!auth || !auth.sub) {
      console.error('No current account', auth);
      return null;
    }

    if (status === STATE.PENDING) {
      // console.log('fetchAccountProjects already called');
      return null;
    }

    commit('SET_STATUS', STATE.PENDING);

    try {
      commit('SET_PROJECTS_STATUS', STATE.PENDING);
      const projAccesses = await fetcher.getProjects();
      const {
        meta: {
          account_id: accountId,
          current_project_id: currentProjectId,
          invites,
          new_project: newProject,
          roles,
          permissions,
          verify_needed: verifyNeeded,
        },
        projects,
        organizations,
      } = projAccesses;

      if (newProject) {
        await $auth.getTokenSilently({ ignoreCache: true });
      }

      const projectsMap = projects.reduce((objIn, projAccess) => {
        const obj = objIn;
        obj[projAccess.project.id] = {
          ...projAccess,
          id: projAccess.project.id,
          projAccessId: projAccess.id,
        };
        return obj;
      }, {});

      const cookiedProjectId = Vue.$cookies.get('current-project-id');
      const useCookiedProjectId =
        cookiedProjectId && projectsMap[cookiedProjectId] ? cookiedProjectId : null;
      const sortedProjs = _sortBy(projects, 'access_level');
      const currProjectId =
        currentProjectId || useCookiedProjectId || _get(sortedProjs, '0.project.id', null);
      const orgId = _get(projectsMap, [currProjectId, 'org_id'], '');

      commit('SET_ACCOUNT_ID', accountId);
      commit('SET_ORG_ID', orgId);
      commit('SET_INVITES', invites || []);
      commit('SET_ORGANIZATIONS', organizations);
      commit('SET_ROLES', roles);
      commit('SET_PERMISSIONS', permissions || []);
      commit('SET_NEW_PROJECT', newProject);
      commit('SET_VERIFY_NEEDED', verifyNeeded);
      commit('SET_PROJECTS', projectsMap);
      commit('SET_PROJECTS_STATUS', STATE.SUCCESS);

      if (currProjectId) {
        if (!projectsMap[currProjectId]) {
          console.log('PROJECT ID NOT IN PROJECTS');
        } else if (!noProjectOk) {
          await dispatch('setProject', { id: currProjectId });
        } else {
          // Had to include this for non-Vue pages being
          // access denied
          await dispatch('setProject', { id: currProjectId });
        }
      }

      commit('SET_STATUS', STATE.SUCCESS);

      return dispatch('updateUserInfo');
    } catch (ex) {
      console.error(ex);
      commit('SET_STATUS', STATE.NOT_SET);
      return null;
    }
  },

  async setProject(
    { dispatch, commit, getters, state: { projectId, nextProjectId, projects, organizations } },
    { id },
  ) {
    console.log('setProject ', id);
    if (id === projectId || id === nextProjectId) {
      console.log('Already in project', id, projectId, nextProjectId);
      return null;
    }
    if (id === nextProjectId) {
      console.log('Already working on setting project', id, projectId, nextProjectId);
      return null;
    }
    commit('SET_NEXT_PROJECT_ID', id);

    try {
      let adminProj = null;

      if (getters.isSiteViewer) {
        if (!projects[id]) {
          const proj = await dispatch('siteAdmin/fetchProject', { id }, { root: true });
          console.log('!!! Fetched project', proj);
          const projAccess = {
            access_level: getters.isSiteAdmin ? 'OWNER' : 'VIEWER',
            account_email: 'info@apimetrics.com',
            account_id: 'site_admin',
            created: '2014-01-01T00:00:00.000000Z',
            id: proj.id,
            last_update: '2014-01-01T00:00:00.000000Z',
            projAccessId: null,
            project: {
              created: proj.created,
              id: proj.id,
              last_update: proj.last_update,
              name: proj.name,
              org_id: proj.organization,
              tags: proj.tags,
              system_tags: proj.system_tags,
            },
          };
          commit('SET_PROJECTS', { ...projects, [proj.id]: projAccess });
          adminProj = projAccess;
        }
        adminProj = adminProj || projects[id];

        const adminOrg = adminProj.project.org_id;
        // Now check if we have the org details
        if (adminOrg && !organizations[adminOrg]) {
          const org = await dispatch('siteAdmin/fetchOrg', { id: adminOrg }, { root: true });
          commit('SET_ORGANIZATIONS', { ...organizations, [org.id]: org });
        }
      } else if (!projects[id]) {
        console.log('No project found');
        commit('SET_NEXT_PROJECT_ID', '');
        return null;
      }

      const projAccess = adminProj || projects[id];

      fetcher.cancelPending(id);
      Vue.$cookies.set('current-project-id', id, '2m');
      const quotaInfo = await dispatch('fetchQuota', projAccess);
      // quotaInfo.counts.org_test_run_count += 10000;
      // quotaInfo.counts.user_test_run_count += 10000;
      return Promise.all([
        dispatch('setProjectId', id),
        dispatch('setQuotaInfo', quotaInfo),
        dispatch('admin/clearAll', null, { root: true }),
        // dispatch('nonVueRoutes/setNonVueProject', { id }, { root: true });
        dispatch('apimAPI/resetApiCache', null, { root: true }),
        dispatch('updateUserInfo'),
      ]).then(() => {
        commit('SET_NEXT_PROJECT_ID', '');
        console.log('setProject  END', id);
      });
    } catch (ex) {
      console.error(ex);
      commit('SET_NEXT_PROJECT_ID', '');
      return null;
    }
  },

  async fetchQuota(_, projectAccess) {
    const data = await fetcher.getQuota();
    if (projectAccess.access_level === 'OWNER' && !projectAccess.project.org_id) {
      try {
        saveHubspotQuotaData(data);
      } catch (ex) {
        console.error(ex);
      }
      // try {
      //   saveIntercomQuotaData(data);
      // } catch (ex) {
      //   console.error(ex);
      // }
    }
    return data;
  },

  updateUserInfo({ state: { projectId, projects: projectsMap, org_id: orgId } }) {
    // eslint-disable-next-line no-underscore-dangle
    const { $auth } = this._vm;
    const auth = $auth.user;
    const user = { ...auth };
    const accessLevel = _get(projectsMap, [projectId, 'access_level'], null);
    const projects = Object.values(projectsMap).filter(({ project }) => !project.org_id);

    const ownedProjects = projects.filter((proj) => proj.access_level === 'OWNER');
    const projectCounts = {
      project_current_access_level: accessLevel,
      project_count: projects.length,
      project_owner_count: ownedProjects.length,
      project_editor_count: projects.filter((proj) => proj.access_level === 'EDITOR').length,
      project_viewer_count: projects.filter((proj) => proj.access_level === 'VIEWER').length,
    };
    // console.log('Saving user data to 3rd parties', user);
    try {
      saveHubspotUserData(user, projectId, orgId, projectCounts, ownedProjects);
    } catch (ex) {
      console.log('hubspot error', ex);
    }
    try {
      saveCrispUserData(user);
    } catch (ex) {
      console.log('Crisp error', ex);
    }
    // try {
    //   saveIntercomUserData(user, projectId, orgId, projectCounts, ownedProjects);
    // } catch (ex) {
    //   console.log('intercom error', ex);
    // }
  },

  updateUserInfoBasic(_store, additionalData) {
    // eslint-disable-next-line no-underscore-dangle
    const { $auth } = this._vm;
    const auth = $auth.user;
    const user = { ...auth, additionalData };
    try {
      saveHubspotUserDataBasic(user);
    } catch (ex) {
      console.log('hubspot error', ex);
    }
    if (window.heap) {
      try {
        window.heap.identify(user.email);
      } catch (ex) {
        console.log('heap error', ex);
      }
    }
    try {
      Bugsnag.setUser(user.sub, user.email, user.name);
    } catch (ex) {
      // eslint-disable-next-line no-console
      console.log('Bugsnag setup err', JSON.stringify(user), ex);
    }
  },

  async setProjectAccess({ state: { projects }, commit, dispatch }, { id, viewer }) {
    const project = { ...projects[id], access_level: viewer ? 'VIEWER' : 'OWNER' };
    commit('SET_PROJECTS', { ...projects, [id]: project });
    dispatch('admin/updateProject', { id, data: project }, { root: true });
  },

  async updateProject({ state: { projects }, commit, dispatch }, { id, data }) {
    const project = await fetcher.updateProject({ id, data });
    commit('SET_PROJECTS', { ...projects, [id]: { ...projects[id], project } });
    dispatch('admin/updateProject', { id, data: project }, { root: true });
  },

  async updateProjectSecurityProfile({ state: { projects }, commit, dispatch }, { id, data }) {
    const project = await fetcher.updateProjectSecurityProfile({ id, data });
    if (project.security_profile) {
      commit('SET_SECURITY_PROFILE', project.security_profile);
    }
    commit('SET_PROJECTS', { ...projects, [id]: { ...projects[id], project } });
    dispatch('admin/updateProject', { id, data: project }, { root: true });
  },

  async getProjectSecurityProfile({ commit }, { id }) {
    const securityProfile = await fetcher.getProjectSecurityProfile({ id });
    commit('SET_SECURITY_PROFILE', securityProfile.security_profile);
  },

  async updateOrganization(
    { commit, state: { organizations } },
    { id, name, enforceMfa, enableKms, tags, passwordExpiryDays },
  ) {
    const org = await fetcher.updateOrganization({
      id,
      data: {
        name,
        enforce_2fa: enforceMfa,
        kms_enabled: enableKms,
        tags,
        password_expiry_days: passwordExpiryDays,
      },
    });
    if (org) {
      commit('SET_ORGANIZATIONS', { ...organizations, [id]: org });
    }
  },

  async createProject({ commit, dispatch, state: { projects } }, { name }) {
    const projectAccess = await fetcher.createProject({ name });
    commit('SET_PROJECTS', {
      ...projects,
      [projectAccess.project.id]: {
        ...projectAccess,
        id: projectAccess.project.id,
        projAccessId: projectAccess.id,
      },
    });

    fetcher.cancelPending(projectAccess.project.id);
    const quotaInfo = await dispatch('fetchQuota', projectAccess);
    dispatch('setQuotaInfo', quotaInfo);
    dispatch('setProject', { id: projectAccess.project.id });
    return projectAccess;
  },

  ...make.actions(state),
};

const getters = {
  ...make.getters(state),
  loading: ({ status }) => status === STATE.PENDING,
  loaded: ({ status }) => status === STATE.SUCCESS,
  projectsLoaded: ({ projectsStatus }) => projectsStatus === STATE.SUCCESS,

  project({ projectId, projects }, { loaded }) {
    if (loaded && projectId) {
      // console.log('project returning ', projects[projectId]);
      return projects[projectId];
    }
    return null;
  },

  getProjectSecurityProfile: (storeState) => {
    return storeState.securityProfile || null;
  },

  isProjectOwner: (_, { loaded, project }) => {
    if (loaded && project) {
      return project.access_level === 'OWNER';
    }
    return false;
  },
  isProjectEditor: (_, { loaded, project }) => {
    if (loaded && project) {
      return project.access_level === 'EDITOR' || project.access_level === 'OWNER';
    }
    return false;
  },
  isProjectAnalyst: (_, { loaded, project }) => {
    if (loaded && project) {
      return project.access_level === 'ANALYST';
    }
    return false;
  },
  isProjectViewOnly: (_, { loaded, project }) => {
    if (loaded && project) {
      return project.access_level === 'VIEWER' || project.access_level === 'ANALYST';
    }
    return false;
  },

  isSiteAdmin: ({ permissions }) => !!permissions.find((perm) => perm === 'site_admin'),
  isSiteAnalyst: ({ permissions }) =>
    !!permissions.find((perm) => perm === 'site_analyst' || perm === 'site_admin'),
  isSiteViewer: ({ permissions }) =>
    !!permissions.find(
      (perm) => perm === 'site_viewer' || perm === 'site_analyst' || perm === 'site_admin',
    ),
};

/* eslint-disable no-param-reassign */
const account2 = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

export default account2;
