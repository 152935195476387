import { make } from 'vuex-pathify';

const state = {
  sortBy: 'insightsScore_score',
  reversed: false,
};
const mutations = make.mutations(state);
const actions = make.actions(state);
const getters = make.getters(state);

/* eslint-disable no-param-reassign */
const sorting = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

export default sorting;
