import _get from 'lodash/get';
import Vue from 'vue';
import { STATE, ALL, STATUS } from './states';

function saveList(name, prefix = 'err') {
  const fn = (state, { status, results, error }) => {
    console.assert(status, `mutations.saveList - ${name} status is not set`);
    if (status === STATE.SUCCESS) {
      const all = _get(state, [name, ALL]);
      console.assert(all, `mutations.saveList - ${name} ALL is not set`);
      console.assert(results, `mutations.saveList - ${name} results is not set`);
      const objs = results.reduce(
        (currObj, obj) => {
          const out = currObj;
          out[obj.id] = obj;
          return out;
        },
        { ...all }, // make a copy, so object is reative
      );
      Vue.set(state[name], ALL, objs);
    }
    if (status === STATE.FAILED) {
      const errName = [prefix, name].join('-');
      Vue.set(state.errors, errName, error);
    }
    Vue.set(state[name], STATUS, status);
  };
  return fn;
}

function saveItem(name, prefix = 'err') {
  const fn = (state, { status, id, data, error }) => {
    console.assert(status, `mutations.saveItem - ${name} status is not set`);
    if (status === STATE.SUCCESS) {
      console.assert(id, `mutations.saveItem - ${name} id is not set`);
      const all = _get(state, [name, ALL]);
      console.assert(all, `mutations.saveItem - ${name} ALL is not set`);
      if (data) {
        Vue.set(all, id, data);
      } else {
        Vue.delete(all, id);
      }
    }
    if (status === STATE.FAILED) {
      console.assert(id, `mutations.saveItem - ${name} id is not set`);
      const errName = [prefix, name, id].join('-');
      Vue.set(state.errors, errName, error);
    }
  };
  return fn;
}

export { saveList, saveItem };
