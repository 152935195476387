<template>
  <div class="alert alert-danger">
    There was a problem loading this page. Please try reloading it.
  </div>
</template>

<script>
export default {
  name: 'ErrorComponent',
};
</script>
