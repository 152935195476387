export const AUTHS = 'QUERY_AUTHS';
export const CALLS = 'QUERY_CALLS';
export const DOWNTIMES = 'QUERY_DOWNTIMES';
export const ENVIRONMENTS = 'QUERY_ENVIRONMENTS';
export const FILES = 'QUERY_FILES';
export const REPORTS = 'QUERY_REPORTS';
export const SCHEDULES = 'QUERY_SCHEDULES';
export const SUBSCRIPTIONS = 'QUERY_SUBSCRIPTIONS';
export const TOKENS = 'QUERY_TOKENS';
export const USERS = 'QUERY_USERS';
export const WORKFLOWS = 'QUERY_WORKFLOWS';

export const CALLS_BY_ORG = 'QUERY_CALL_BY_ORG';
export const SCHEDULES_BY_CALL = 'QUERY_SCHEDULE_BY_CALL';
export const SCHEDULES_BY_WORKFLOW = 'QUERY_SCHEDULE_BY_WORKFLOW';
export const TOKENS_BY_AUTH = 'QUERY_TOKEN_BY_AUTH';
export const WORKFLOWS_BY_CALL = 'QUERY_WORKFLOW_BY_CALL';
