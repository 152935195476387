import { regex } from 'vee-validate/dist/rules';
import urlRegex from 'url-regex';

const apimVarRegexes = ['', /%%[A-Z0-9_]+%%/g, /__[A-Z0-9_]+__/g, /{{ *[A-Za-z]\w* *}}/g];
const regexUrl = urlRegex();
const regexUrlFull = urlRegex({ exact: true });

const url = (value) => {
  console.log('Validate url', value);
  return regex.validate(value, { regex: regexUrlFull });
};

const urlWithVariables = (value) => {
  // Check for valid urls, then swap a potential variable out for example.com and try again
  const valid = apimVarRegexes.reduce((validIn, reg) => {
    if (!validIn) {
      const val = value.replace(reg, 'example.com');
      const alt = value.replace(reg, 'https://example.com');
      return regex.validate(val, { regex: regexUrl }) || regex.validate(alt, { regex: regexUrl });
    }
    return validIn;
  }, false);
  return valid;
};

const isVariable = (value) =>
  apimVarRegexes.reduce((valid, reg) => valid || !!value.match(reg), false);

const json = async (value) => {
  try {
    await JSON.parse(value);
  } catch (ex) {
    return `${ex.name}: ${ex.message}`;
  }
  return true;
};

export { url, urlWithVariables, isVariable, json };
