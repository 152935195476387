export const AUTH = 'CREATE_AUTH';
export const CALL = 'CREATE_CALL';
export const DOWNTIME = 'CREATE_DOWNTIME';
export const ENVIRONMENT = 'CREATE_ENVIRONMENT';
export const FILE = 'CREATE_FILE';
export const REPORT = 'CREATE_REPORT';
export const SCHEDULE = 'CREATE_SCHEDULE';
export const SUBSCRIPTION = 'CREATE_SUBSCRIPTION';
export const TOKEN = 'CREATE_TOKEN';
export const USER = 'CREATE_USER';
export const WORKFLOW = 'CREATE_WORKFLOW';
