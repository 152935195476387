// Error handler
export const STORE_ERROR = 'Store Error';
export const CLEAR_ERRORS = 'Clear Errors';

// Lists of Objects
export const STORE_AUTHS = '⏬ Store Auth Settings';
export const STORE_CALLS = '⏬ Store API Calls';
export const STORE_DOWNTIMES = '⏬ Store Downtimes';
export const STORE_ENVIRONMENTS = '⏬ Store Environments';
export const STORE_FILES = '⏬ Store Files';
export const STORE_REPORTS = '⏬ Store Reports';
export const STORE_SCHEDULES = '⏬ Store Schedules';
export const STORE_SUBSCRIPTIONS = '⏬ Store Subscriptions';
export const STORE_TOKENS = '⏬ Store Tokens';
// export const STORE_USERS = '⏬ Store Users';
export const STORE_WORKFLOWS = '⏬ Store Workflows';

// Individual objects in a list
export const SAVE_AUTH = '🔽 Update an Auth Settings';
export const SAVE_CALL = '🔽 Update an API Call';
export const SAVE_CALL_BY_ORG = '🔽 Update an Organization API Call';
export const SAVE_DOWNTIME = '🔽 Update Downtime';
export const SAVE_ENVIRONMENT = '🔽 Update Environment';
export const SAVE_FILE = '🔽 Update File';
export const SAVE_REPORT = '🔽 Update Report';
export const SAVE_SCHEDULE = '🔽 Update Schedule';
export const SAVE_SUBSCRIPTION = '🔽 Update Subscription';
export const SAVE_TOKEN = '🔽 Update Token';
// export const SAVE_USER = '🔽 Update User';
export const SAVE_WORKFLOW = '🔽 Update Workflows';
