import { STATE } from './states';
import { handleException } from '../apim-api';

function list(MUTATION, fetchFn) {
  console.assert(fetchFn, 'no such fetch fn for list', MUTATION);
  const fn = async ({ commit }, params) => {
    commit(MUTATION, { status: STATE.PENDING });
    return fetchFn({
      ...params,
      pageCb: (resp) => {
        const { results } = resp;
        commit(MUTATION, { status: STATE.SUCCESS, results });
      },
    }).catch(async (except) => {
      const error = await handleException(except);
      commit(MUTATION, { state: STATE.FAILED, error });
      return null;
    });
  };
  return fn;
}

function get(MUTATION, fetchFn) {
  console.assert(fetchFn, 'no such fetch fn for get', MUTATION);
  const fn = async ({ commit }, params) => {
    commit(MUTATION, { status: STATE.PENDING, id: params.id });
    const data = await fetchFn(params).catch(async (except) => {
      const error = await handleException(except);
      commit(MUTATION, { state: STATE.FAILED, id: params.id, error });
      return null;
    });
    if (data) {
      commit(MUTATION, { status: STATE.SUCCESS, id: params.id, data });
    }
    return data;
  };
  return fn;
}

function create(MUTATION, fetchFn) {
  console.assert(fetchFn, 'no such fetch fn for create', MUTATION);
  const fn = async ({ commit }, params) => {
    commit(MUTATION, { status: STATE.PENDING });
    const data = await fetchFn(params).catch(async (except) => {
      const error = await handleException(except);
      commit(MUTATION, { state: STATE.FAILED, error });
      return null;
    });
    if (data) {
      commit(MUTATION, { status: STATE.SUCCESS, id: data.id, data });
    }
    return data;
  };
  return fn;
}

function update(MUTATION, fetchFn) {
  console.assert(fetchFn, 'no such fetch fn for update', MUTATION);
  const fn = async ({ commit }, params) => {
    //  console.log('actions.update', MUTATION, params);
    commit(MUTATION, { status: STATE.PENDING, id: params.id });
    const data = await fetchFn(params).catch(async (except) => {
      const error = await handleException(except);
      commit(MUTATION, { state: STATE.FAILED, id: params.id, error });
      return null;
    });
    if (data) {
      //  console.log('actions.update success', MUTATION, params, data);
      commit(MUTATION, { status: STATE.SUCCESS, id: params.id, data });
    }
    return data;
  };
  return fn;
}

function remove(MUTATION, fetchFn) {
  console.assert(fetchFn, 'no such fetch fn for remove', MUTATION);
  const fn = async ({ commit }, params) => {
    commit(MUTATION, { status: STATE.PENDING, id: params.id });
    const success = await fetchFn(params).catch(async (except) => {
      const error = await handleException(except);
      commit(MUTATION, { state: STATE.FAILED, id: params.id, error });
      return null;
    });
    if (success) {
      commit(MUTATION, { status: STATE.SUCCESS, id: params.id });
    }
    return success;
  };
  return fn;
}

export { list, get, create, update, remove };
