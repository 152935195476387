// import moment from 'moment-timezone';
import _get from 'lodash/get';
import _cloneDeep from 'lodash/cloneDeep';
import _reduce from 'lodash/reduce';
import _concat from 'lodash/concat';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import _flatten from 'lodash/flatten';
import _uniq from 'lodash/uniq';
import Vue from 'vue';
import emojiFlags from 'emoji-flags';
import { APImInterface } from './fetch';
import * as mutations from './mutation-types';
import { STATE } from './state';
import {
  fetcher,
  handleException,
  actionFetch,
  actionFetchList,
  actionRequireObjects,
  actionRequireObjectById,
} from './actions';
import { STANDARD } from './standard-types';

const apimAPI = {
  namespaced: true,
  state: {
    ..._cloneDeep(STANDARD.state),
    agentInfo: {
      locations: {},
      locationsShort: {},
      postmanLocations: [],
      regions: [],
      agents: {},
    },

    loading: {
      ..._cloneDeep(STANDARD.loadingState),
      agentInfo: STATE.NOT_SET,
    },
    apiErrors: {},
    latestError: null,
  },
  getters: {
    // list{Object}s
    // get{Object}

    // Could use...STANDARD.getters, but better to be explicit:
    listAuths: STANDARD.getters.listAuths,
    getAuth: STANDARD.getters.getAuth,
    stateAuths: STANDARD.getters.stateAuths,
    stateAuth: STANDARD.getters.stateAuth,

    listCalls: STANDARD.getters.listCalls,
    getCall: STANDARD.getters.getCall,
    stateCalls: STANDARD.getters.stateCalls,
    stateCall: STANDARD.getters.stateCall,

    getAllTags(state, getters) {
      const sorter = (a, b) => {
        if (a.includes(':') && !b.includes(':')) {
          return 1;
        }
        if (!a.includes(':') && b.includes(':')) {
          return -1;
        }
        return a.localeCompare(b);
      };

      let output = [];
      if (state.loading.calls.ALL === STATE.SUCCESS) {
        const tagsArrs = getters.listCalls.map(({ meta }) => meta.tags || []);
        const tags = _flatten(tagsArrs);
        output = tags;
      }
      if (state.loading.workflows.ALL === STATE.SUCCESS) {
        const tagsArrs = getters.listWorkflows.map(({ meta }) => meta.tags || []);
        const tags = _flatten(tagsArrs);
        output = [...output, ...tags];
      }
      return _uniq(output).sort(sorter);
    },

    getAllFileTags(state, getters) {
      const sorter = (a, b) => a.localeCompare(b);
      let output = [];
      if (state.loading.files.ALL === STATE.SUCCESS) {
        const tagsArrs = getters.listFiles.map(({ meta }) => meta.tags || []);
        const tags = _flatten(tagsArrs);
        output = tags;
      }
      return _uniq(output).sort(sorter);
    },

    listDowntimes: STANDARD.getters.listDowntimes,
    getDowntime: STANDARD.getters.getDowntime,
    stateDowntimes: STANDARD.getters.stateDowntimes,
    stateDowntime: STANDARD.getters.stateDowntime,

    listEnvironments: STANDARD.getters.listEnvironments,
    getEnvironment: STANDARD.getters.getEnvironment,
    stateEnvironments: STANDARD.getters.stateEnvironments,
    stateEnvironment: STANDARD.getters.stateEnvironment,

    listFiles: STANDARD.getters.listFiles,
    getFile: STANDARD.getters.getFile,
    stateFiles: STANDARD.getters.stateFiles,
    stateFile: STANDARD.getters.stateFile,

    listReports: STANDARD.getters.listReports,
    getReport: STANDARD.getters.getReport,
    stateReports: STANDARD.getters.stateReports,
    stateReport: STANDARD.getters.stateReport,

    listSchedules: STANDARD.getters.listSchedules,
    getSchedule: STANDARD.getters.getSchedule,
    stateSchedules: STANDARD.getters.stateSchedules,
    stateSchedule: STANDARD.getters.stateSchedule,

    listSubscriptions: STANDARD.getters.listSubscriptions,
    getSubscription: STANDARD.getters.getSubscription,
    stateSubscriptions: STANDARD.getters.stateSubscriptions,
    stateSubscription: STANDARD.getters.stateSubscription,

    listTokens: STANDARD.getters.listTokens,
    getToken: STANDARD.getters.getToken,
    stateTokens: STANDARD.getters.stateTokens,
    stateToken: STANDARD.getters.stateToken,

    // listUsers: STANDARD.getters.listUsers,
    // getUser: STANDARD.getters.getUser,
    // stateUsers: STANDARD.getters.stateUsers,
    // stateUser: STANDARD.getters.stateUser,

    listWorkflows: STANDARD.getters.listWorkflows,
    getWorkflow: STANDARD.getters.getWorkflow,
    stateWorkflows: STANDARD.getters.stateWorkflows,
    stateWorkflow: STANDARD.getters.stateWorkflow,

    getLocName: (state) => (locationId) => {
      if (state.loading.agentInfo === STATE.SUCCESS) {
        return _get(state.agentInfo.locations, locationId, locationId);
      }
      return locationId;
    },

    getLocNameShort: (state) => (locationId) => {
      if (state.loading.agentInfo === STATE.SUCCESS) {
        return _get(state.agentInfo.locationsShort, locationId, locationId);
      }
      return locationId;
    },

    scheduledTargets(state, getters) {
      const workflows = getters.listWorkflows || [];
      const targets = _reduce(
        getters.listSchedules,
        (targs, { schedule }) => _concat(targs, schedule.target_ids),
        [],
      );
      const scheduledWorkflows = _filter(workflows, (workflow) =>
        _find(targets, (targId) => targId === workflow.id),
      );
      const callsInWorkflows = _reduce(
        scheduledWorkflows,
        (targs, { workflow }) => _concat(targs, workflow.call_ids),
        [],
      );
      return _uniq(_concat(targets, callsInWorkflows));
    },

    userCalls(state, getters) {
      return (getters.listCalls || []).filter(({ meta }) => meta.workspace !== '_meta');
    },

    scheduledCalls(state, getters) {
      const calls = getters.userCalls.filter(({ id, meta }) => {
        const scheduled = !!_find(getters.scheduledTargets, (targ) => targ === id);
        const readOnly = meta.tags.indexOf('read_only') >= 0;
        const alwaysMonitored = meta.tags.indexOf('apimetrics:monitored') >= 0;
        const notMonitored = meta.tags.indexOf('apimetrics:not_monitored') >= 0;
        return alwaysMonitored || (!notMonitored && (scheduled || readOnly));
      });
      return calls;
    },

    scheduledWorkflows(state, getters) {
      const workflows = getters.listWorkflows.filter(({ id }) => {
        const scheduled = !!_find(getters.scheduledTargets, (targ) => targ === id);
        return scheduled;
      });
      return workflows;
    },

    // List getters - filter by other key
    getDowntimesBySchedule(state) {
      return ({ scheduleId }) =>
        Object.values(state.downtimes).filter(
          ({ meta }) => meta && meta.schedule_id === scheduleId,
        );
    },

    getSchedulesByCall(state, getters) {
      return ({ callId }) =>
        getters.listSchedules.filter((sch) => sch.schedule.target_ids.includes(callId));
    },
    getSchedulesByWorkflow(state, getters) {
      return ({ workflowId }) =>
        getters.listSchedules.filter((sch) => sch.schedule.target_ids.includes(workflowId));
    },
    getTokensByAuth(state, getters) {
      return ({ authId }) => getters.listTokens.filter(({ meta }) => meta.auth_id === authId);
    },
    getWorkflowsByCall(state, getters) {
      return ({ callId }) =>
        getters.listWorkflows.filter(({ workflow }) => workflow.call_ids.includes(callId));
    },

    // Item getters by ID

    getScheduledLocations(state, getters) {
      return ({ callId }) => {
        // console.log('getScheduledLocations', callId);

        const workflows = getters.listWorkflows || [];
        const worksflowsForCall = _filter(workflows, ({ workflow }) =>
          _find(workflow.call_ids, (id) => id === callId),
        ).map(({ id }) => id);

        // Call Id + all workflow Ids that have that call
        const targets = [callId, ...worksflowsForCall];
        // console.log('getScheduledLocations targets', targets);

        // Find schedules for any of these
        const schedules = _filter(getters.listSchedules || [], ({ schedule }) =>
          targets.reduce(
            (found, inId) => found || _find(schedule.target_ids, (id) => id === inId),
            false,
          ),
        );

        // console.log('getScheduledLocations schedules', schedules);

        const info = schedules.reduce(
          (soFar, { schedule: { locations = [], regions = [] } }) => ({
            locations: [...soFar.locations, ...locations],
            regions: [...soFar.regions, ...regions],
          }),
          { locations: [], regions: [] },
        );
        // console.log('getScheduledLocations info', info);

        const locations = _uniq(info.locations);
        const regions = _uniq(info.regions);

        const regionLocs = regions.reduce((soFar, region) => {
          const regInfo = _find(state.agentInfo.regions, ({ id }) => id === region);
          return [...soFar, ...regInfo.locations];
        }, []);
        // console.log('getScheduledLocations regionLocs', regionLocs);

        const allLocs = _uniq([...locations, ...regionLocs]);
        // console.log('getScheduledLocations allLocs', allLocs);
        return allLocs;
      };
    },
  },
  mutations: {
    CLEAR_CACHE: (state) => {
      Object.entries(STANDARD.state).forEach(([key, val]) => {
        Vue.set(state, key, _cloneDeep(val));
      });
      Object.entries(STANDARD.loadingState).forEach(([key, val]) => {
        Vue.set(state.loading, key, _cloneDeep(val));
      });
    },

    [mutations.STORE_ERROR]: (state, { name, error }) => {
      Vue.set(state.apiErrors, name, error);
      Vue.set(state, 'latestError', error);
    },
    [mutations.CLEAR_ERRORS]: (state) => {
      state.apiErrors = {};
      Vue.set(state, 'latestError', null);
    },

    // STORE_{OBJECT}S
    // SAVE_{OBJECT}
    // Could use...STANDARD.mutations, but explicit is better than implicit
    [mutations.STORE_AUTHS]: STANDARD.mutations[mutations.STORE_AUTHS],
    [mutations.SAVE_AUTH]: STANDARD.mutations[mutations.SAVE_AUTH],
    [mutations.STORE_CALLS]: STANDARD.mutations[mutations.STORE_CALLS],
    [mutations.SAVE_CALL]: STANDARD.mutations[mutations.SAVE_CALL],
    [mutations.STORE_DOWNTIMES]: STANDARD.mutations[mutations.STORE_DOWNTIMES],
    [mutations.SAVE_DOWNTIME]: STANDARD.mutations[mutations.SAVE_DOWNTIME],
    [mutations.STORE_ENVIRONMENTS]: STANDARD.mutations[mutations.STORE_ENVIRONMENTS],
    [mutations.SAVE_ENVIRONMENT]: STANDARD.mutations[mutations.SAVE_ENVIRONMENT],
    [mutations.STORE_FILES]: STANDARD.mutations[mutations.STORE_FILES],
    [mutations.SAVE_FILE]: STANDARD.mutations[mutations.SAVE_FILE],
    [mutations.STORE_REPORTS]: STANDARD.mutations[mutations.STORE_REPORTS],
    [mutations.SAVE_REPORT]: STANDARD.mutations[mutations.SAVE_REPORT],
    [mutations.STORE_SCHEDULES]: STANDARD.mutations[mutations.STORE_SCHEDULES],
    [mutations.SAVE_SCHEDULE]: STANDARD.mutations[mutations.SAVE_SCHEDULE],
    [mutations.STORE_SUBSCRIPTIONS]: STANDARD.mutations[mutations.STORE_SUBSCRIPTIONS],
    [mutations.SAVE_SUBSCRIPTION]: STANDARD.mutations[mutations.SAVE_SUBSCRIPTION],
    [mutations.STORE_TOKENS]: STANDARD.mutations[mutations.STORE_TOKENS],
    [mutations.SAVE_TOKEN]: STANDARD.mutations[mutations.SAVE_TOKEN],
    // [mutations.STORE_USERS]: STANDARD.mutations[mutations.STORE_USERS],
    // [mutations.SAVE_USER]: STANDARD.mutations[mutations.SAVE_USER],
    [mutations.STORE_WORKFLOWS]: STANDARD.mutations[mutations.STORE_WORKFLOWS],
    [mutations.SAVE_WORKFLOW]: STANDARD.mutations[mutations.SAVE_WORKFLOW],

    SAVE_AGENT_INFO: (state, { status, data, error }) => {
      state.loading.agentInfo = status;
      if (status === STATE.FAILED) {
        Vue.set(state.agentInfo, 'error', error);
      } else if (status === STATE.SUCCESS) {
        Vue.set(state.agentInfo, 'regions', data.regions);
        Vue.set(state.agentInfo, 'locations', data.locations);
        Vue.set(state.agentInfo, 'postmanLocations', data.postman_locations);
        Vue.set(
          state.agentInfo,
          'agents',
          data.agent_list.reduce((agents, agent) => ({ ...agents, [agent.id]: agent }), {}),
        );
        Vue.set(
          state.agentInfo,
          'locationsShort',
          data.agent_list.reduce((agents, obj) => {
            const output = agents;
            const { agent } = obj;
            const info = agent.country ? emojiFlags.countryCode(agent.country) : null;
            const region = agent.region && agent.region !== '?' ? `${agent.region} ` : '';
            if (info) {
              const { emoji, name } = info;
              output[obj.id] = `${agent.cloud} ${region}${emoji} ${name}`;
            } else {
              output[obj.id] = `${agent.cloud} ${region}${agent.country}`;
            }
            return output;
          }, {}),
        );
      }
    },
  },
  actions: {
    resetApiCache({ commit }) {
      commit('CLEAR_CACHE');
    },
    storeAPIError({ commit }, { name, error }) {
      commit(mutations.STORE_ERROR, { name, error });
    },
    clearAPIErrors({ commit }) {
      commit(mutations.CLEAR_ERRORS, {});
    },

    // fetch{Object}s
    // fetch{Object}
    // create{Object}
    // update{Object}
    // delete{Object}
    // require{Object}s
    // require{Object}

    // Could use ...STANDARD.actions here, but explicit is better than implicit
    fetchAuths: STANDARD.actions.fetchAuths,
    fetchAuth: STANDARD.actions.fetchAuth,
    createAuth: STANDARD.actions.createAuth,
    updateAuth: STANDARD.actions.updateAuth,
    deleteAuth: STANDARD.actions.deleteAuth,
    requireAuths: STANDARD.actions.requireAuths,
    requireAuth: STANDARD.actions.requireAuth,

    fetchCalls: STANDARD.actions.fetchCalls,
    fetchCall: STANDARD.actions.fetchCall,
    createCall: STANDARD.actions.createCall,
    updateCall: STANDARD.actions.updateCall,
    deleteCall: STANDARD.actions.deleteCall,
    requireCalls: STANDARD.actions.requireCalls,
    requireCall: STANDARD.actions.requireCall,

    // fetchDowntimes: STANDARD.actions.fetchDowntimes,
    fetchDowntime: STANDARD.actions.fetchDowntime,
    createDowntime: STANDARD.actions.createDowntime,
    updateDowntime: STANDARD.actions.updateDowntime,
    deleteDowntime: STANDARD.actions.deleteDowntime,
    // requireDowntimes: STANDARD.actions.requireDowntimes,
    // requireDowntime: STANDARD.actions.requireDowntime,

    fetchEnvironments: STANDARD.actions.fetchEnvironments,
    fetchEnvironment: STANDARD.actions.fetchEnvironment,
    createEnvironment: STANDARD.actions.createEnvironment,
    updateEnvironment: STANDARD.actions.updateEnvironment,
    deleteEnvironment: STANDARD.actions.deleteEnvironment,
    requireEnvironments: STANDARD.actions.requireEnvironments,
    requireEnvironment: STANDARD.actions.requireEnvironment,

    fetchFiles: STANDARD.actions.fetchFiles,
    fetchFile: STANDARD.actions.fetchFile,
    createFile: STANDARD.actions.createFile,
    updateFile: STANDARD.actions.updateFile,
    deleteFile: STANDARD.actions.deleteFile,
    requireFiles: STANDARD.actions.requireFiles,
    requireFile: STANDARD.actions.requireFile,

    fetchReports: STANDARD.actions.fetchReports,
    fetchReport: STANDARD.actions.fetchReport,
    createReport: STANDARD.actions.createReport,
    updateReport: STANDARD.actions.updateReport,
    deleteReport: STANDARD.actions.deleteReport,
    requireReports: STANDARD.actions.requireReports,
    requireReport: STANDARD.actions.requireReport,

    fetchSchedules: STANDARD.actions.fetchSchedules,
    fetchSchedule: STANDARD.actions.fetchSchedule,
    createSchedule: STANDARD.actions.createSchedule,
    updateSchedule: STANDARD.actions.updateSchedule,
    deleteSchedule: STANDARD.actions.deleteSchedule,
    requireSchedules: STANDARD.actions.requireSchedules,
    requireSchedule: STANDARD.actions.requireSchedule,

    fetchSubscriptions: STANDARD.actions.fetchSubscriptions,
    fetchSubscription: STANDARD.actions.fetchSubscription,
    createSubscription: STANDARD.actions.createSubscription,
    updateSubscription: STANDARD.actions.updateSubscription,
    deleteSubscription: STANDARD.actions.deleteSubscription,
    requireSubscriptions: STANDARD.actions.requireSubscriptions,
    requireSubscription: STANDARD.actions.requireSubscription,

    fetchTokens: STANDARD.actions.fetchTokens,
    fetchToken: STANDARD.actions.fetchToken,
    createToken: STANDARD.actions.createToken,
    updateToken: STANDARD.actions.updateToken,
    deleteToken: STANDARD.actions.deleteToken,
    requireTokens: STANDARD.actions.requireTokens,
    requireToken: STANDARD.actions.requireToken,

    // fetchUsers: STANDARD.actions.fetchUsers,
    // fetchUser: STANDARD.actions.fetchUser,
    // createUser: STANDARD.actions.createUser,
    // updateUser: STANDARD.actions.updateUser,
    // deleteUser: STANDARD.actions.deleteUser,
    // requireUsers: STANDARD.actions.requireUsers,
    // requireUser: STANDARD.actions.requireUser,

    fetchWorkflows: STANDARD.actions.fetchWorkflows,
    fetchWorkflow: STANDARD.actions.fetchWorkflow,
    createWorkflow: STANDARD.actions.createWorkflow,
    updateWorkflow: STANDARD.actions.updateWorkflow,
    deleteWorkflow: STANDARD.actions.deleteWorkflow,
    requireWorkflows: STANDARD.actions.requireWorkflows,
    requireWorkflow: STANDARD.actions.requireWorkflow,

    requireMonitoredCalls: async ({ dispatch }) => {
      const futs = [
        dispatch('requireCalls'),
        dispatch('requireWorkflows'),
        dispatch('requireSchedules'),
      ];
      return Promise.all(futs);
    },

    requireMonitoredWorkflows: async ({ dispatch }) => {
      const futs = [dispatch('requireWorkflows'), dispatch('requireSchedules')];
      return Promise.all(futs);
    },

    requireAgentInfo: async ({ state, dispatch }) => {
      const path = 'agentInfo';
      const currState = _get(state.loading, path, STATE.NOT_SET);

      if (currState === STATE.NOT_SET) {
        await dispatch('fetchAgentInfo');
      }
      // No need for a getter for a simple state like this
      return state.agentInfo;
    },
    fetchAgentInfo: async ({ commit }) => {
      const mutation = 'SAVE_AGENT_INFO';
      commit(mutation, { status: STATE.PENDING });
      const data = await fetcher.getAgentInfo().catch(async (except) => {
        if (except.name !== 'AbortError') {
          const error = await handleException(except);
          commit(mutation, { status: STATE.FAILED });
          commit(mutations.STORE_ERROR, { name: 'getAgentInfo', error });
        }
      });
      if (data) {
        commit(mutation, { status: STATE.SUCCESS, data });
      }
    },

    //
    // Get a List of Objects
    //

    fetchSchedulesByCall: actionFetchList(
      mutations.STORE_SCHEDULES,
      APImInterface.QUERY.SCHEDULES_BY_CALL,
    ),
    requireSchedulesByCall: actionRequireObjects(
      ({ callId }) => ['schedules', callId],
      'fetchSchedulesByCall',
      'getSchedulesByCall',
    ),

    fetchSchedulesByWorkflow: actionFetchList(
      mutations.STORE_SCHEDULES,
      APImInterface.QUERY.SCHEDULES_BY_WORKFLOW,
    ),
    requireSchedulesByWorkflow: actionRequireObjects(
      ({ workflowId }) => ['schedules', workflowId],
      'fetchSchedulesByWorkflow',
      'getSchedulesByWorkflow',
    ),

    fetchTokensByAuth: actionFetchList(mutations.STORE_TOKENS, APImInterface.QUERY.TOKENS_BY_AUTH),
    requireTokensByAuth: actionRequireObjects(
      ({ authId }) => ['tokens', authId],
      'fetchTokensByAuth',
      'getTokensByAuth',
    ),

    fetchWorkflowsByCall: actionFetchList(
      mutations.STORE_WORKFLOWS,
      APImInterface.QUERY.WORKFLOWS_BY_CALL,
    ),
    requireWorkflowsByCall: actionRequireObjects(
      ({ callId }) => ['workflows', callId],
      'fetchWorkflowsByCall',
      'getWorkflowsByCall',
    ),

    fetchDowntimesBySchedule: actionFetchList(
      mutations.STORE_DOWNTIMES,
      APImInterface.QUERY.DOWNTIMES,
    ),
    requireDowntimesBySchedule: actionRequireObjectById(
      'downtimes',
      'scheduleId',
      'fetchDowntimesBySchedule',
      'getDowntimesBySchedule',
    ),

    addCallToSchedule: actionFetch(mutations.SAVE_SCHEDULE, 'addCallToSchedule'),
    removeCallFromSchedule: actionFetch(mutations.SAVE_SCHEDULE, 'removeCallFromSchedule'),

    addWorkflowToSchedule: actionFetch(mutations.SAVE_SCHEDULE, 'addWorkflowToSchedule'),
    removeWorkflowFromSchedule: actionFetch(mutations.SAVE_SCHEDULE, 'removeWorkflowFromSchedule'),

    uploadOas: actionFetch(mutations.SAVE_FILE, 'uploadOas'),
    uploadOasUrl: actionFetch(mutations.SAVE_FILE, 'uploadOasUrl'),
  },
};

export { apimAPI, fetcher, STATE, handleException };
