import './site_inits';
import Vue from 'vue';
import { ValidationProvider, ValidationObserver, extend, setInteractionMode } from 'vee-validate';
import {
  // eslint-disable-next-line camelcase
  required,
  min,
  min_value,
  max_value,
  numeric,
  regex,
  email,
} from 'vee-validate/dist/rules';
import VTooltip from 'v-tooltip';

import VueCookies from 'vue-cookies';
import vueNumeralFilterInstaller from 'vue-numeral-filter';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';
import Donut from 'vue-css-donut-chart';
import GridContainer from './components/GridContainer.vue';
import GridRow from './components/GridRow.vue';
import GridCol from './components/GridCol.vue';
import 'vue-css-donut-chart/dist/vcdonut.css';
import router from './router';
import { Auth0Plugin } from './auth';
import store from './store';
import { fetcher } from './store/apim-api';
import { url, urlWithVariables, isVariable, json } from './store/custom-validators';
import './store/tooltip.css';
import './js/common.css';

// const APIM_TOKEN_KEY = 'apimAccessToken';
const domain = process.env.VUE_APP_AUTH0_DOMAIN;
const clientId = process.env.VUE_APP_AUTH0_CLIENT_ID;
const audience = process.env.VUE_APP_AUTH0_AUDIENCE;
const redirectUri = process.env.VUE_APP_AUTH0_REDIRECT_URI;

const isDev = process.env.NODE_ENV !== 'production';
Vue.config.performance = isDev;

if (process.env.VUE_APP_BUGSNAG_API_KEY) {
  Bugsnag.start({
    apiKey: process.env.VUE_APP_BUGSNAG_API_KEY,
    appVersion: process.env.VUE_APP_VERSION,
    plugins: [new BugsnagPluginVue()],
  });
  const bugsnagVue = Bugsnag.getPlugin('vue');
  bugsnagVue.installVueErrorHandler(Vue);
}

Vue.use(VTooltip, {
  defaultHtml: false,
});
Vue.use(vueNumeralFilterInstaller);
Vue.use(VueCookies);
Vue.use(Auth0Plugin, {
  domain,
  clientId,
  audience,
  redirectUri,
  onRedirectCallback: (appState) => {
    router.push(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname);
  },
});

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
setInteractionMode('eager');
extend('required', required);
extend('json', json);
extend('min', min);
extend('min_value', min_value);
extend('max_value', max_value);
extend('numeric', numeric);
extend('regex', regex);
extend('email', email);
extend('url', url);
extend('url_with_variables', urlWithVariables);
extend('is_variable', isVariable);

Vue.component('GridContainer', GridContainer);
Vue.component('GridRow', GridRow);
Vue.component('GridCol', GridCol);
Vue.use(Donut);

Vue.prototype.$fetcher = fetcher;

function createVueApp() {
  const app = new Vue({
    el: '#app',
    store,
    router,
    render(createElement) {
      return createElement('router-view');
    },
  });

  app.$fetcher.getToken = async () => {
    try {
      const token = await app.$auth.getTokenSilently();
      // window.sessionStorage.setItem(APIM_TOKEN_KEY, token);
      return token;
    } catch (ex) {
      console.warn(ex);
    }
    return null;
  };

  return app;
}

const app = createVueApp();

export default {
  app,
};
