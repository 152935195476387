export const AUTH = 'DELETE_AUTH';
export const CALL = 'DELETE_CALL';
export const DOWNTIME = 'DELETE_DOWNTIME';
export const ENVIRONMENT = 'DELETE_ENVIRONMENT';
export const FILE = 'DELETE_FILE';
export const REPORT = 'DELETE_REPORT';
export const SCHEDULE = 'DELETE_SCHEDULE';
export const SUBSCRIPTION = 'DELETE_SUBSCRIPTION';
export const TOKEN = 'DELETE_TOKEN';
export const USER = 'DELETE_USER';
export const WORKFLOW = 'DELETE_WORKFLOW';
