const STATE = Object.freeze({
  NOT_SET: 'NOT_SET',
  PENDING: 'PENDING',
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED',
});

const ALL = Object.freeze('ALL');
const STATUS = Object.freeze('STATUS');

function init() {
  return {
    [STATUS]: STATE.NOT_SET,
    [ALL]: {},
  };
}

export { STATE, ALL, STATUS, init };
