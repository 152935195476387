import _get from 'lodash/get';
import _toPath from 'lodash/toPath';
import { STATE, getPath } from './state';

function getterList(pathIn) {
  return (state) => {
    const path = _toPath(pathIn);
    const valObj = _get(state, path, {});
    const vals = Object.values(valObj).filter((val) => val.id);
    // console.debug('Getter List', path, vals.length);
    return vals;
  };
}

function getterById(statePathFn, stateKeyFn) {
  return (state) => (params) => {
    const path = getPath(statePathFn, stateKeyFn, params);
    const item = _get(state, path, null);
    // console.debug('Getter By Id', path, item);
    return item;
  };
}

function getLoadState(statePathFn, stateKeyFn) {
  return (state) => (params) => {
    const path = getPath(statePathFn, stateKeyFn, params);
    const item = _get(state.loading, path, STATE.NOT_SET);
    // console.debug('Getter State', path, item);
    return item;
  };
}

export { getterList, getterById, getLoadState };
