import _isFunction from 'lodash/isFunction';
import _toPath from 'lodash/toPath';
import _get from 'lodash/get';
import _has from 'lodash/has';
import _isEmpty from 'lodash/isEmpty';
import Vue from 'vue';

const STATE = Object.freeze({
  NOT_SET: 'NOT_SET',
  PENDING: 'PENDING',
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED',
});

const ALL = Object.freeze('ALL');

function getPath(statePathFn, stateKeyFn, params, data) {
  const stateKeyPath = _isFunction(statePathFn) ? statePathFn(params) : _toPath(statePathFn);
  let key = _isFunction(stateKeyFn) ? stateKeyFn(params) : _get(params, stateKeyFn);

  if (key === undefined && data) {
    key = _isFunction(stateKeyFn) ? stateKeyFn(data) : _get(data, stateKeyFn);
  }

  const itemKeyPath = [...stateKeyPath, key];
  return itemKeyPath;
}

function setVuePath(state, statePath, val) {
  const path = [...statePath];
  const lastKey = path.pop();
  const valToSave = val !== undefined ? val : {};

  path.reduce((subState, key) => {
    if (!_has(subState, key)) {
      Vue.set(subState, key, {});
    }
    return subState[key];
  }, state);

  const theState = _isEmpty(path) ? state : _get(state, path);
  Vue.set(theState, lastKey, valToSave);
}

export { STATE, ALL, getPath, setVuePath };
