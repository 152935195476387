import { STATE } from './states';
import { handleException } from '../apim-api';

const getId = ({ id }) => id;

function list(MUTATION, fetchFn, pathFromParams = getId) {
  console.assert(fetchFn, `action-fn: list ${MUTATION} - no such fetchFn`);
  const fn = async ({ commit }, params) => {
    const path = pathFromParams(params);
    console.assert(path, `action-fn: list ${MUTATION} - no path`);
    commit(MUTATION, { status: STATE.PENDING, path });
    return fetchFn({
      ...params,
      pageCb: (resp) => {
        const { results } = resp;
        commit(MUTATION, { status: STATE.SUCCESS, path, results });
      },
    }).catch(async (except) => {
      const error = await handleException(except);
      commit(MUTATION, { state: STATE.FAILED, path, error });
      return null;
    });
  };
  return fn;
}

function get(MUTATION, fetchFn, pathFromParams = getId) {
  console.assert(fetchFn, `action-fn: get ${MUTATION} - no such fetchFn`);
  const fn = async ({ commit }, params) => {
    const path = pathFromParams(params);
    console.assert(path, `action-fn: get ${MUTATION} - no path for`, params);
    commit(MUTATION, { status: STATE.PENDING, path });
    const data = await fetchFn(params).catch(async (except) => {
      const error = await handleException(except);
      commit(MUTATION, { state: STATE.FAILED, path, error });
      return null;
    });
    if (data) {
      commit(MUTATION, {
        status: STATE.SUCCESS,
        path,
        id: params.id,
        data,
      });
    }
    return data;
  };
  return fn;
}

function create(MUTATION, fetchFn, pathFromParams = getId) {
  console.assert(fetchFn, `action-fn: create ${MUTATION} - no such fetchFn`);
  const fn = async ({ commit }, params) => {
    const path = pathFromParams(params);
    console.assert(path, `action-fn: create ${MUTATION} - no path`);
    commit(MUTATION, { status: STATE.PENDING, path });
    const data = await fetchFn(params).catch(async (except) => {
      const error = await handleException(except);
      commit(MUTATION, { state: STATE.FAILED, path, error });
      return null;
    });
    if (data) {
      commit(MUTATION, {
        status: STATE.SUCCESS,
        path,
        id: data.id,
        data,
      });
    }
  };
  return fn;
}

function update(MUTATION, fetchFn, pathFromParams = getId) {
  console.assert(fetchFn, `action-fn: update ${MUTATION} - no such fetchFn`);
  const fn = async ({ commit }, params) => {
    const path = pathFromParams(params);
    console.assert(path, `action-fn: update ${MUTATION} - no path`);
    commit(MUTATION, { status: STATE.PENDING, path });
    const data = await fetchFn(params).catch(async (except) => {
      const error = await handleException(except);
      commit(MUTATION, { state: STATE.FAILED, path, error });
      return null;
    });
    if (data) {
      commit(MUTATION, {
        status: STATE.SUCCESS,
        path,
        id: data.id,
        data,
      });
    }
  };
  return fn;
}

function remove(MUTATION, fetchFn, pathFromParams, idFromParams = getId) {
  console.assert(fetchFn, `action-fn: remove ${MUTATION} - no such fetchFn`);
  const fn = async ({ commit }, params) => {
    console.assert(pathFromParams, `action-fn: remove ${MUTATION} - no pathFromParams`);
    const path = pathFromParams(params);
    console.assert(path, `action-fn: remove ${MUTATION} - no path`);
    commit(MUTATION, { status: STATE.PENDING, path });
    const success = await fetchFn(params).catch(async (except) => {
      const error = await handleException(except);
      commit(MUTATION, { state: STATE.FAILED, path, error });
      return null;
    });
    if (success) {
      const id = idFromParams(params);
      commit(MUTATION, { status: STATE.SUCCESS, path, id });
    }
  };
  return fn;
}

export { list, get, create, update, remove };
