import * as QUERY from './query';
import * as CREATE from './create';
import * as READ from './read';
import * as UPDATE from './update';
import * as DELETE from './delete';

const APImInterface = {
  QUERY,
  CREATE,
  READ,
  UPDATE,
  DELETE,
};

export default APImInterface;
