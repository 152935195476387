import Vue from 'vue';
import Vuex from 'vuex';

import pathify from './pathify';
// import account from './account';
import account2 from './account2';
import admin from './admin';
import siteAdmin from './siteAdmin';
import { apimAPI } from './apim-api';
import sorting from './sorting';
import nonVueRoutes from './nonVueRoutes';

Vue.use(Vuex);

const modules = {
  // account,
  account2,
  admin,
  siteAdmin,
  apimAPI,
  sorting,
  nonVueRoutes,
};

const store = new Vuex.Store({
  plugins: [pathify.plugin], // activate plugin
  modules,
  strict: process.env.NODE_ENV !== 'production',
});

export default store;
