import _get from 'lodash/get';
import _sortBy from 'lodash/sortBy';
import { STATE, ALL, STATUS } from './states';

function needed(name) {
  const fn = (state) => {
    const val = _get(state, [name, STATUS]);
    return val === STATE.NOT_SET;
  };
  return fn;
}

function loaded(name) {
  const fn = (state) => _get(state, [name, STATUS]) === STATE.SUCCESS;
  return fn;
}

function listOf(name, sortByFn) {
  const fn = (state) => {
    const all = _get(state, [name, ALL]);
    console.assert(all, 'getters.listOf - all is not set');
    const values = Object.values(all);
    return _sortBy(values, [(item) => (_get(item, sortByFn) || '').toLowerCase()]);
  };
  return fn;
}

function getById(name, getAttr) {
  if (!getAttr) {
    const fn = (state) => (id) => _get(state, [name, ALL, id]);
    return fn;
  }
  const fn = (state) => (id) =>
    Object.values(_get(state, [name, ALL])).find((obj) => getAttr(obj) === id);
  return fn;
}

export { needed, loaded, listOf, getById };
